<template>
  <div class="home" :class="isMobile ? 'mobile' : ''">
    <br />
    <h1>TopNFlow Privacy Policy</h1>
    <p>Last updated: August 28, 2021</p>
    <div class="content pb-5">
      <!-- <div class="row" key="updateHorizontalSlider"></div> -->
      <div class="pt-2 mb-5">
        <div class="row">
          <div class="sub-topic-content mt-5">
            <!-- <h4 class="title">Privacy Policy</h4> -->
            <b-card>
              <b-card-text>
                <p>
                  This Privacy Policy describes how we collect, use, and
                  disclose information collected through the TopNFlow.com
                  website, including IP location. This Privacy Policy also
                  applies to information we may collect and process via our
                  social media pages and accounts (Facebook, LinkedIn, YouTube
                  and Twitter) and when you use certain TopNFlow applications
                  and extensions that connect to your TopNFlow online account to
                  our website.
                </p>
                <p>
                  The TopNFlow.com website is owned by Chicmomo Inc.
                  (hereinafter referred to as “we”, “us”, “TopNFlow” or the
                  “Company”), located in the United States. If you are located
                  in the European Economic Area (EEA) or Switzerland, these
                  terms of will also apply to you.
                </p>
                <p>
                  Some TopNFlow partners may operate United States and other
                  countries. The privacy protections and rights of authorities
                  to access your information in these countries may not be
                  equivalent to those in the jurisdiction where you are located.
                  We will only transfer your personal information to these
                  countries where permitted to do so by law. Your information
                  will be protected same as the extent we give to visitors from
                  United States.
                </p>
                <p>TopNFlow <u> does not sell your personal information</u>.</p>
                <p>
                  <b
                    >If the content or information that you disclose to us
                    contains the personal information of other individuals, you
                    must be legally permitted to share it with us.
                  </b>
                </p>
                <p style="color: #2e5395; font-size: 16pt">
                  What does this privacy policy cover?
                </p>
                <p>
                  This privacy policy describes how we will make use of your
                  information in the context of:
                </p>
                <ul>
                  <li>
                    TopNFlow.com websites (including sub-domains to which you
                    may be redirected based on your location);
                  </li>
                  <li>
                    If you have a TopNFlow account, your use of the TopNFlow
                    platform and certain extensions or applications once you log
                    in to your account;
                  </li>
                  <li>
                    Personal information shared with us or that which you make
                    publicly available when interacting with our social media
                    accounts or by visiting our pages on social media sites;
                    however, such sites are not owned, managed or controlled by
                    TopNFlow. The collection, use, and disclosure of your
                    personal information while visiting these other sites is
                    subject to their respective privacy policies (not
                    TopNFlow’s) -- so please review those policies before
                    disclosing any personal information on those sites; and
                  </li>
                  <li>
                    TopNFlow’s online marketing, sales, and advertising
                    practices.
                  </li>
                </ul>
                <p>
                  <b
                    >This privacy policy does not cover the privacy practices of
                    TopNFlow merchants or other entities that use TopNFlow for
                    e-commerce on their websites. If you are the customer of a
                    merchant or other third party related to TopNFlow website,
                    please refer to the privacy policy on that site for
                    information about how they may use and disclose of your
                    personal data.</b
                  >
                </p>
                <p>
                  If you are a TopNFlow business customer, you may be subject to
                  additional privacy terms in place of or as a supplement to
                  this privacy policy as part of a license or other commercial
                  agreement in place between you and TopNFlow.
                </p>
                <p style="color: #2e5395; font-size: 16pt">Please note:</p>
                <ul>
                  <li>
                    By using our website (including any TopNFlow.com
                    subdomains), signing up for TopNFlow promotional emails,
                    registering for a TopNFlow account, accessing the TopNFlow
                    administrative console, and/or interact with
                    TopNFlow-managed social media pages on third party platforms
                    (e.g., Facebook, Twitter, LinkedIn, or YouTube) you agree to
                    the terms of this Privacy Policy, which includes information
                    about the choices you have with respect to how we use and
                    share your data.
                  </li>
                  <li>
                    We will obtain your permission before (i) sending you
                    promotional material about TopNFlow products and services;
                    and (ii) accessing information stored on your device
                    relating to your use of, and engagement with, our website,
                    social media pages and crash reports. You can withdraw your
                    consent for us to engage in these activities at any time.
                    This can be done by opting out using the unsubscribe link
                    found at the bottom of our marketing emails, or by
                    contacting us through the contact us page.
                  </li>
                  <li>
                    This policy explains when we process personal information
                    for our legitimate interests. We may use your personal
                    information to send you important information about your
                    account; provide the TopNFlow products and services you have
                    requested from us; authenticate users who access the
                    TopNFlow platform and/or sign in to a TopNFlow account;
                    monitor performance and activity on our website, systems and
                    networks; and for other purposes described in this Privacy
                    Policy to the extent permitted by law.
                  </li>
                  <li>
                    We may also share your contact information with our
                    advertising and sales partners, consistent with your
                    choices.
                  </li>
                  <li>
                    We may disclose information to third parties we engage to
                    process data on our behalf, when disclosure may be required
                    in order for us to comply with legal obligations or in
                    certain other situations if required by law.
                  </li>
                  <li>
                    We provide interactive features that engage with social
                    media sites, such as Facebook, Twitter, LinkedIn and
                    YouTube. If you use these features, these sites will send us
                    personal information about you.
                  </li>
                  <li>
                    We use cookies and other technologies to track the use of
                    our websites and apps. To learn about your cookie choices or
                    to disallow cookies from our site, click
                    <a
                      href="http://www.allaboutcookies.org/manage-cookies/index.html"
                    >
                      here to learn about cookies </a
                    >.
                  </li>
                </ul>
                <p style="color: #2e5395; font-size: 16pt">
                  What personal information does TopNFlow collect?
                </p>
                <p>
                  When you register on our website or sign up for email or
                  contact us for support, TopNFlow collects information that
                  identifies you. This information may include:
                </p>
                <ul>
                  <li>Name;</li>
                  <li>Company name;</li>
                  <li>Email address;</li>
                  <li>Username and other login credentials;</li>
                  <li>Telephone number;</li>
                  <li>Address;</li>
                  <li>Country;</li>
                  <li>
                    Payment/billing information (if you purchase something from
                    us online);
                  </li>
                  <li>
                    Whether you have provided consent for us to use and/or share
                    your personal information in a specific way and the time and
                    date such consent was given;
                  </li>
                  <li>
                    and Information you provide to us through customer support
                    communications.
                  </li>
                </ul>
                <p>
                  To help keep our databases current and to provide you with the
                  most relevant content and experiences, we may combine
                  information provided by you with information from third party
                  sources, in accordance with applicable law. For example, the
                  size, industry, and other information about the company you
                  work for (where you have provided company name) will be
                  obtained from sources including professional networking sites,
                  business databases and registries, and other information we
                  are able to obtain from our service providers.
                </p>

                <p>
                  We collect information about how you use our websites,
                  extensions, social media content and our platform. This
                  information may be anonymous, or it may be associated with
                  you. It includes:
                </p>
                <ul>
                  <li>IP address;</li>
                  <li>Type of browser and device;</li>
                  <li>Webpage that led you to a TopNFlow website;</li>
                  <li>
                    Search terms entered into a search engine which lead you to
                    a TopNFlow website;
                  </li>
                  <li>
                    How you use and navigate across our website, the TopNFlow
                    platform, and certain extensions (collected through cookies
                    and similar technologies, or by TopNFlow servers when you
                    are logged in to the website or platform);
                  </li>
                  <li>
                    Analysis of your content (e.g., activity logs, and direct
                    feedback from you); and
                  </li>
                  <li>
                    Other data that is necessary in order for us to provide
                    products or services to you, including information sent to
                    us in order for extensions or applications you add to your
                    TopNFlow deployment to operate.
                  </li>
                </ul>
                <p style="color: #2e5395; font-size: 16pt">
                  How does TopNFlow use my personal information?
                </p>
                <p>
                  TopNFlow uses the personal information for the following
                  purposes:
                </p>
                <ol type="a">
                  <li>
                    Providing you with access to your TopNFlow administrative
                    console and other
                  </li>
                  <li>
                    TopNFlow products and services you have purchased from us;
                  </li>
                  <li>Verifying your identity;</li>
                  <li>
                    Sending you necessary communications (for example, related
                    to payments or expiration of your subscription); and
                  </li>
                  <li>
                    Analyzing your use and measuring the effectiveness of our
                    websites, platforms and extensions to better understand how
                    they are being used and improve our customer experience;
                  </li>
                  <li>
                    To enhance, improve, modify or create new products and
                    services; o For determining the effectiveness of our
                    promotional campaigns, so that we can adapt them to the
                    needs and interests of our users;
                  </li>
                  <li>
                    Using automated systems to analyze your content using
                    techniques such as machine learning to improve our services
                    and the user experience
                  </li>
                  <li>
                    Diagnosing problems with our websites, platforms, or
                    network;
                  </li>
                  <li>
                    Conducting surveys and market research about customers,
                    their interests, the effectiveness of our marketing
                    campaigns, and customer satisfaction
                  </li>
                  <li>
                    Investigating and responding to any comments or complaints
                    that you may send to us
                  </li>
                  <li>
                    To prevent fraud, misuse and illegal activity our websites,
                    platforms and systems such as to detect and prevent
                    cyberattacks or attempts to commit identity theft; and
                  </li>
                  <li>
                    In connection with legal claims, audits, compliance,
                    regulatory and investigative purposes as necessary
                    (including disclosure of information in connection with a
                    contractual obligation, legal process or litigation).
                  </li>
                </ol>
                <p>
                  • Where you give TopNFlow your consent or when doing so is
                  otherwise consistent with your choices we will:
                </p>
                <ul style="list-style-type: circle">
                  <li>
                    Send you information about TopNFlow products and services,
                    special offers and similar information, and share your
                    information with third parties for their own marketing
                    purposes (if we have your consent);
                  </li>
                  <li>
                    Place cookies and use similar technologies on our websites,
                    on our apps and in email communications, in accordance with
                    our Cookies Policy and the information available to you when
                    those technologies are used.
                  </li>
                  <li>
                    Access crash reports and other information stored on your
                    device relating to your use of, and engagement with,
                    websites and applications.
                  </li>
                  <li>
                    On other occasions where we ask you for consent, we will use
                    the information only for the purpose(s) for which you have
                    agreed that it may be used unless we are required to do
                    otherwise by law.
                  </li>
                </ul>
                <h6>USE OF SERVICES BY MINORS</h6>
                <p>
                  Our website and Services not directed to individuals under the
                  age of sixteen (16), and we do not knowingly collect Personal
                  Information from individuals under sixteen (16).
                </p>
                <p style="color: #2e5395; font-size: 16pt">
                  Personal information?
                </p>
                <p><b>Sharing with other third parties </b></p>
                <p>
                  TopNFlow may also share your personal information with other
                  third parties:
                </p>
                <ul>
                  <li>
                    When you agree to the sharing by disclosing it to others,
                    e.g., you post content on message boards, online chats,
                    blogs, TopNFlow Forums and our social media pages (please
                    note that any information you post or disclose via these
                    methods will become public and may be available to other
                    users and the general public and we are not responsible for
                    the information collection, use, disclosure or security of
                    these third parties);
                  </li>
                  <li>
                    When we are required to provide information in response to a
                    subpoena, court order, or other applicable law or legal
                    process (note that this may include laws or legal
                    obligations imposed by other jurisdictions other than where
                    you are located);
                  </li>
                  <li>
                    When we have a good faith belief that the disclosure is
                    necessary to prevent or respond to fraud, defend our systems
                    and networks against attacks, or protect the property and
                    safety of TopNFlow, our customers and users, or the public;
                    and
                  </li>
                  <li>
                    If we merge with or are acquired by another company, sell a
                    TopNFlow website, app, or business unit, or if all or a
                    substantial portion of our assets are acquired by another
                    company, your information will likely be disclosed to our
                    advisers and any prospective purchaser's advisers and will
                    be one of the assets that is transferred to the new owner.
                  </li>
                </ul>
                <p>
                  This privacy policy does not address, and we are not
                  responsible for, the privacy, information or other practices
                  of any third parties, including any third party operating any
                  website or service that may be accessible via a link on our
                  platform or websites. The inclusion of a link on a TopNFlow
                  webpage does not imply endorsement of the linked site or
                  service by us or by our affiliates.
                </p>
                <p style="color: #2e5395; font-size: 16pt">
                  Other information: collection and use
                </p>
                <p>
                  To the extent permitted by law, we may use and share aggregate
                  or pseudonymized information that doesn’t specifically
                  identify you (“Other Information”), such as statistical
                  information about how customers use our customer support
                  services, website, and extensions; browser information, device
                  type, demographic information that does not identify you, and
                  data collected from cookies, pixel tags and other technologies
                  that are not linked or dissociated from individual identifiers
                  (like cookie IDs). We use this information to ensure that our
                  website, and related online services are working correctly. If
                  we are required to treat Other Information as personal
                  information under applicable law, then we may use and disclose
                  it for the purposes for which we use and disclose personal
                  information as detailed in this privacy policy.
                </p>
                <p><b>Cookies and Pixels </b></p>
                <p>
                  Cookies are pieces of information stored directly on the
                  computer that you are using. Cookies allow us to collect
                  information such as browser type, time spent on our websites
                  and platforms, pages visited, language preferences, and other
                  anonymous traffic data. We and our service providers use the
                  information for security purposes, to facilitate navigation,
                  to display information more effectively, and to personalize
                  your experience. We also gather statistical information about
                  use of the services in order to continually improve their
                  design and functionality, understand how they are used, to
                  monitor website performance, and track the effectiveness of
                  our online marketing activities.
                </p>
                <p>
                  Cookies also help us track the success of our online ads by
                  providing information about which ads are displayed to you,
                  whether they were clicked on and where the ad was displayed.
                </p>
                <p>
                  We do not respond to do-not-track signals. We engage in
                  cross-site tracking, which means that we may serve you ads
                  after visiting our site as part of a retargeting campaign.
                </p>
                <p>
                  If you do not want information collected through the use of
                  cookies, most browsers allow you to automatically decline
                  cookies or be given the choice of declining or accepting a
                  particular cookie (or cookies) from a particular website. You
                  can also refer
                  <a
                    href="http://www.allaboutcookies.org/manage-cookies/index.html"
                  >
                    here</a
                  >
                  to learn more about cookies and how to manage your cookie
                  preferences online.
                </p>
                <p>
                  If you do not accept cookies, you may not be able to use some
                  of the features on our websites and some functions may not
                  work correctly. You also may not receive advertising or other
                  offers from us that may be relevant and tailored to your
                  interests. For more information about our cookies and your
                  choices regarding them can be found in our Cookie Policy.
                </p>
                <p>
                  In addition to cookies, we use pixel tags (also known as web
                  beacons and clear GIFs) to, among other things, track user
                  engagement with our online ads and email messages, measure the
                  success of our marketing campaigns and compile statistics
                  about response rates, and understand more about how our online
                  services are used.
                </p>
                <p><b>Please don’t send sensitive information </b></p>
                <p>
                  Please do not send nor disclose to us any sensitive personal
                  information (e.g., social security or other government
                  identification numbers, information related to racial or
                  ethnic origin, political opinions, religion or other beliefs,
                  health, biometrics or genetic characteristics, criminal
                  background or trade union membership) on or through our
                  website forms or customer support process, or any other means
                  of communication.
                </p>
                <p style="color: #2e5395; font-size: 16pt">
                  We make great efforts to keep your personal information is
                  secure.
                </p>
                <p>
                  We understand that the security of your personal information
                  is important. We provide reasonable administrative, technical,
                  and physical security controls to protect your personal
                  information. However, despite our efforts, no security
                  controls are 100% effective so please be careful when
                  disclosing your personal information to anyone online. Many of
                  the security features are also offered by our technology
                  partners and the their privacy policy governs in those cases.
                </p>
                <p style="color: #2e5395; font-size: 16pt">
                  Where does TopNFlow store my personal information?
                </p>
                <p>
                  Your personal information and files are stored on servers of
                  companies we hire to provide services to us. The servers
                  practically can be located anywhere in the United States.
                </p>
                <p style="color: #2e5395; font-size: 16pt">
                  Does TopNFlow transfer my personal information across national
                  borders?
                </p>
                <p>
                  We don’t transfer your personal information across national
                  borders. The partner suppliers may have mechanisms during
                  upgrade of their services or routing during downtime or
                  cyberattack that may create backups or transfers across
                  national boundaries.
                </p>

                <p>
                  <b
                    >Withdrawing consent or otherwise objecting to direct
                    marketing
                  </b>
                </p>
                <p>
                  Wherever we rely on your consent to process your personal
                  information, you will always be able to withdraw that consent,
                  although we may have other legal grounds to continue
                  processing your information, such as those set out above. In
                  some cases, we are able to send you marketing information
                  without your consent, where we rely on our legitimate
                  interests. You have an absolute right to opt-out of direct
                  marketing communications or the profiling we carry out for
                  marketing purposes at any time by:
                </p>
                <ul>
                  <li>
                    updating your preferences in your TopNFlow account profile;
                  </li>
                  <li>
                    updating your preferences in your specific website or app
                    accounts;
                  </li>
                  <li>
                    clicking the unsubscribe link at the bottom of our marketing
                    emails; or
                  </li>
                  <li>
                    contacting us using the details provided at the end of this
                    privacy policy.
                  </li>
                </ul>
                <p><b>Updates, corrections, restrictions and copies </b></p>
                <p>
                  For your protection, we will only implement requests with
                  respect to the personal information associated with the
                  particular email address that you use to send us your request,
                  and we may need to verify your identity before implementing
                  your request. We will try to comply with your request as soon
                  as reasonably practicable.
                </p>
                <p>
                  Please note that we may need to retain certain information for
                  record-keeping purposes and/or to complete any transactions
                  that you began prior to requesting a change or deletion. There
                  may also be residual non-identifiable information that will
                  remain in our databases and other records which will not be
                  removed.
                </p>

                <p style="color: #2e5395; font-size: 16pt">
                  Your Rights as a California Consumer
                </p>
<p>In addition to the rights set out above, California law also provides for some additional rights for California consumers, which are summarized below.

</p>
<p><b>Categories of personal information
</b></p>
<p>You have the right to obtain a list of the categories of personal information collected about you, and other related information such as the source of the personal information, categories of personal information shared or sold to third parties, and the purpose for such sharing. TopNFlow has provided this information in this Privacy Notice.

</p>
<p><b>Additional information on exercising your rights
</b></p>
<p>You have the right to exercise the rights listed above free of charge or penalty, but we may limit the number of requests you make or charge reasonable fees as legally permitted. 
<br>
You may designate an authorized agent to exercise some of your rights; however, in order to help protect the security of your personal information, the authorized agent must follow the same authentication procedures that are required if you exercise your rights without using an agent.  TopNFlow will verify requests made through authorized agents to help ensure the safety of your account and to comply with our policies and procedures.


</p>
<p style="color: #2e5395; font-size: 16pt">
  Data Retention

</p>
<p>We will retain your personal information for as long as needed in light of the purpose(s) for which it was provided. The criteria used to determine our retention periods include: (i) the length of time we have an ongoing relationship with you and provide the services we agreed to you; (ii) when we have legal obligation to which we are subject; or (iii) as advisable in light of our legal position (such as in regard to applicable statutes of limitations, litigation or regulatory investigations).

</p>
<p style="color: #2e5395; font-size: 16pt">
Changes to this policy

</p>
<p>Occasionally, we may change this privacy policy to allow us to use or share your personal information in a different way. If we do, the links to the policy on our websites (which are generally found in the footer of the website) will indicate that the policy has been changed. For new users, the change will become effective upon posting. For existing users, if the change is significant, it will become effective 30 days after posting. We encourage you to periodically review this page for the latest information on our privacy practices.

</p>
<p style="color: #2e5395; font-size: 16pt">
Contact

</p>
<p>If you have a privacy question, concern, or request, please send us an using the <a href="/contact-us">contact us</a> page.</p>




              </b-card-text>
            </b-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Privacy",
  data() {
    return {
      
    };
  },
  
};
</script>
