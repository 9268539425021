<template>
  <!-- <div> -->
  <div class="home" :class="isMobile ? 'mobile' : ''">
    <br />
    <h1>Contact Us</h1>
    <div class="content pb-5">
      <div class="pt-2 mb-5">
        <div class="row">
          <div class="sub-topic-content mt-5">
            <div class="error-message" v-if="showalert">
              <b-alert show variant="info">
                <template>
                  <div>
                    <p>You will be notified soon!</p>
                  </div>
                </template>
              </b-alert>
            </div>
            <!-- <h4 class="title">Contact Us</h4> -->
            <b-card>
              <b-card-text
                >Lorem Ipsum is simply dummy text of the printing and
                typesetting industry. From lorem Ipsum has been the industry's
                standard dummy text ever since the 1500s, when an unknown
                printer took a galley of type and scrambled it to
                make.</b-card-text
              >
              <!-- <b-card-text> -->
              <b-form @submit="onSubmit" @reset="onReset" v-if="show">
                <b-form-group
                  id="input-group-2"
                  label="Your Name:"
                  label-for="input-2"
                >
                  <b-form-input
                    id="input-2"
                    v-model="form.name"
                    placeholder="Enter name"
                    required
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  id="input-group-1"
                  label="Email address:"
                  label-for="input-1"
                  description="We'll never share your email with anyone else."
                >
                  <b-form-input
                    id="input-1"
                    v-model="form.email"
                    type="email"
                    placeholder="Enter email"
                    required
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  id="input-group-2"
                  label="Your Address:"
                  label-for="input-2"
                >
                  <b-form-input
                    id="input-2"
                    v-model="form.address"
                    placeholder="Enter Address"
                    required
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  id="input-group-2"
                  label="Your Contact:"
                  label-for="input-2"
                >
                  <b-form-input
                    id="input-2"
                    v-model="form.contact"
                    placeholder="Enter contact"
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  id="input-group-2"
                  label="Subject:"
                  label-for="input-2"
                >
                  <b-form-input
                    id="input-2"
                    v-model="form.subject"
                    placeholder="Enter Subject"
                    required
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  id="input-group-2"
                  label="Message:"
                  label-for="input-2"
                >
                  <b-form-textarea
                    id="input-2"
                    v-model="form.message"
                    placeholder="Enter message"
                    required
                    rows="3"
                    max-rows="6"
                  ></b-form-textarea>
                </b-form-group>

                <!-- <b-form-group
                  id="input-group-3"
                  label="Food:"
                  label-for="input-3"
                >
                  <b-form-select
                    id="input-3"
                    v-model="form.food"
                    :options="foods"
                    required
                  ></b-form-select>
                </b-form-group> -->

                <!-- <b-form-group id="input-group-4" v-slot="{ ariaDescribedby }">
                  <b-form-checkbox-group
                    v-model="form.checked"
                    id="checkboxes-4"
                    :aria-describedby="ariaDescribedby"
                  >
                    <b-form-checkbox value="me">Check me out</b-form-checkbox>
                    <b-form-checkbox value="that"
                      >Check that out</b-form-checkbox
                    >
                  </b-form-checkbox-group>
                </b-form-group> -->

                <b-button type="submit" variant="primary" @click="saveContact()"
                  >Submit</b-button
                >
                <b-button type="reset" variant="danger">Reset</b-button>
              </b-form>
              <!-- </b-card-text> -->
            </b-card>
          </div>
        </div>
      </div>
    </div>

    <!-- </div> -->

    <!-- <b-card class="mt-3" header="Form Data Result">
      <pre class="m-0">{{ form }}</pre>
    </b-card> -->
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      form: {
        email: "",
        address: "",
        contact: "",
        name: "",
        subject: "",
        message: "",
        // food: null,
        // checked: [],
      },
      // foods: [
      //   { text: "Select One", value: null },
      //   "Carrots",
      //   "Beans",
      //   "Tomatoes",
      //   "Corn",
      // ],
      show: true,
      showalert: false,
    };
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();
      // alert('You will be Notified soon');
      this.showalert = true;
      // alert(JSON.stringify(this.form));
    },
    onReset(event) {
      event.preventDefault();
      // Reset our form values
      this.form.email = "";
      this.form.address = "";
      this.form.contact = "";
      this.form.subject = "";
      this.form.message = "";
      this.form.name = "";
      // this.form.food = null;
      // this.form.checked = [];
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
    saveContact() {
      const config = {
        headers: {
          authorization: `${this.$store.state.token}`,
        },
      };
      // if (this.isSubTopic) {
      //   this.topic.parent = this.getParentMain?.id
      //     ? this.getParentMain?.id
      //     : null;
      // } else {
      //   this.topic.parent = this.getParentMain?.parent
      //     ? this.getParentMain.parent
      //     : null;
      // }
      axios
        .post(`${process.env.VUE_APP_API_URL}/contact/`, this.form, config)
        .then((res) => {
          this.form = {
            email: "",
            address: "",
            contact: "",
            name: "",
            subject: "",
            message: "",
          };
          // this.$refs["createTopicModal"].hide();
          // this.getTopicList(true, res.data.id);
        });
    },
  },
};
</script>