import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '@/views/Home.vue'
import ForgotPassword from '@/views/ForgotPassword.vue'
import Terms from '@/views/Terms.vue'
import About from '@/views/About.vue'
import Privacy from '@/views/Privacy.vue'
import Contact from '@/views/Contact.vue'
import Topic from '@/views/topic.vue'

import NotFound from '@/components/NotFound.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    name: 'NotFound',
    component: NotFound,
  }, 
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      auth: false,
    },
  },
  {
    path: '/api-auth/password-reset-confirm/:uid/:token',
    name: 'forgotpassword',
    component: ForgotPassword,
    meta: {
      auth: false,
    },
  },
  {
    path: '/termsofuse',
    name: 'termsofuse',
    component: Terms,
    meta: {
      auth: false,
    },
  },
  {
    path: '/privacypolicy',
    name: 'privacypolicy',
    component: Privacy,
    meta: {
      auth: false,
    },
  },
  {
    path: '/about',
    name: 'about',
    component: About,
    meta: {
      auth: false,
    },
  },
  {
    path: '/contact-us',
    name: 'contact-us',
    component: Contact,
    meta: {
      auth: false,
    },
  },
  {
    path: '/topic/:id',
    name: 'Topic',
    component: Topic,
    meta: {
      auth: false,
    },
  },
  {
    path: '/:slug/',
    name: 'share_topic',
    component: Home,
    meta: {
      auth: false,
    },
  },
  {
    path: '/:slug/topic/:id',
    name: 'TopicAfterShared',
    component: Topic,
    meta: {
      auth: false,
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
