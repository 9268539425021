<template>
  <!-- <div> -->
  <div class="home" :class="isMobile ? 'mobile' : ''">
    <br />
    <h1>Password Reset Confirm</h1>
    <div class="content pb-5">
      <div class="pt-2 mb-5">
        <div class="row align-items-center justify-content-center">
          <div class="sub-topic-content mt-5 ">
            <div class="error-message" v-if="showalert">
              <b-alert show variant="info">
                <template>
                  <div>
                    <p>{{alert}}!</p>
                  </div>
                </template>
              </b-alert>
            </div>
            <!-- <h4 class="title">Contact Us</h4> -->
            <b-card>
              <b-card-text
                >Please enter your new password twice so we can verify you typed it in correctly.</b-card-text
              >
              <!-- <b-card-text> -->
              <b-form @submit="onSubmit" @reset="onReset" v-if="show">
                <b-form-group
                  id="input-group-2"
                  label="Your New Password:"
                  label-for="input-2"
                >
                  <b-form-input
                    id="input-2"
                    v-model="form.new_password1"
                    placeholder="Enter Password"
                    required
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  id="input-group-1"
                  label="Password Confirm:"
                  label-for="input-1"
                >
                  <b-form-input
                    id="input-1"
                    v-model="form.new_password2"
                    type="email"
                    placeholder="Enter Password"
                    required
                  ></b-form-input>
                </b-form-group>

                <b-button type="submit" variant="primary" @click="Save()"
                  >Submit</b-button
                >
                <!-- <b-button type="reset" variant="danger">Reset</b-button> -->
              </b-form>
              <!-- </b-card-text> -->
            </b-card>
          </div>
        </div>
      </div>
    </div>

    <!-- </div> -->

    <!-- <b-card class="mt-3" header="Form Data Result">
      <pre class="m-0">{{ form }}</pre>
    </b-card> -->
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      form: {
        new_password1: "",
        new_password2: "",
      },
      show: true,
      showalert: false,
      alert:'',
    };
  },
  computed:{
isMobile() {
      return window.screen.width < 769;
    },
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();
      // alert('You will be Notified soon');
      // alert(JSON.stringify(this.form));
    },
    onReset(event) {
      event.preventDefault();
      // Reset our form values
      this.form.new_password1 = "";
      this.form.new_password2 = "";
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
    Save() {
      const config = {
        headers: {
          authorization: `${this.$store.state.token}`,
        },
      };
      console.log(this.$route.params.uid)
      var formData = new FormData();

      formData.append('new_password1',this.form.new_password1)
      formData.append('new_password2',this.form.new_password2)
      formData.append('uid',this.$route.params.uid)
      formData.append('token',this.$route.params.token)
      console.log('formData',formData)
    console.log('form',this.form)
      axios
        .post(`${process.env.VUE_APP_API_URL}/api-auth/password-reset-confirm/${this.$route.params.uid}/${this.$route.params.token}/`, formData, config)
        .then((res) => {
            console.log(res.data)
this.alert = res.data.detail
      this.showalert = true;
          this.form = {
            new_password1: "",
            new_password2: "",
          };
          
          // this.$refs["createTopicModal"].hide();
          // this.getTopicList(true, res.data.id);
        }).catch((err) => {
            console.log(err)
        //   this.errorList = err.response.data;
        });
    },
  },
};
</script>