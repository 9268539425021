<template>
	<div id="NotFound">
		<h1>Error 404 - not found.</h1>
	</div>
</template>

<script>
export default {
	name: "NotFound"
};
</script>

<style lang="scss">
#NotFound {
}
</style>
