<template>
  <div class="header">
    <div class="container">
      <div class="menu d-flex">
        <h4 class="brand cursor-pointer" @click="$router.push('/')">
          <img width="200px" src="@/assets/TopNFlow 2 5000x5000.svg" alt="" />
          <!-- <img width="200px" src="@/assets/logo-old.png" alt="" /> -->
        </h4>
        <p v-if="!isLoggedIn" class="login cursor-pointer">
          <span v-b-modal.login>Log In</span> /
          <span v-b-modal.register>Create Account</span>
        </p>
        <p v-else class="login cursor-pointer">
          <span @click="logout()">Logout</span>
          &nbsp;
          <span>{{ this.$store.state.username }}</span>
        </p>
      </div>
    </div>
    <b-modal id="login" ref="login" hide-footer title="Login">
      <div>
        <b-form-group label="Username">
          <b-form-input v-model="loginCredentials.username" trim></b-form-input>
        </b-form-group>
      </div>
      <div style="text-align: right;">
      <a class="text-primary cursor-pointer" @click="forgotPassModal()">Forgot Password?</a>
      </div>
      <div>
        <b-form-group label="Password">
          <b-form-input
            type="password"
            v-model="loginCredentials.password"
            trim
          ></b-form-input>
        </b-form-group>
      </div>
      <div class="error-message" v-if="Object.keys(errorList).length">
        <b-alert show variant="danger">
          <template v-for="(error, index) in Object.keys(errorList)">
            <div :key="index" v-if="isObjectArray(errorList[error])">
              <strong>{{ error }}:</strong>
              <p :key="ind" v-for="(err, ind) in errorList[error]">{{ err }}</p>
              <!-- <p :key="ind" v-for="(err, ind) in errorList[error]">{{ err }}</p> -->
            </div>
            <div :key="index" v-else>
              <strong>{{ error }}:</strong>
              <p :key="ind" >{{ errorList[error] }}</p>
            </div>
          </template>
        </b-alert>
      </div>
      <div>
        <b-button
          :disabled="!isLoginFormValid"
          class="mt-3"
          block
          variant="primary"
          @click="login()"
          >Login</b-button
        >
      </div>
    </b-modal>

    <b-modal id="forgotPassword" ref="forgotPassword" hide-footer title="Forgot Password">
      <div>Forgotten your password? Enter your email address below, and we'll email instructions for setting a new one.<br/> Please make sure you enter the address you registered with.<br/></div><br/>
      <div>
        <b-form-group label="Email">
          <b-form-input v-model="forgotPassewordCredentials.email" trim></b-form-input>
        </b-form-group>
      </div>
      <div class="error-message" v-if="Object.keys(errorList).length">
        <b-alert show variant="danger">
          <template v-for="(error, index) in Object.keys(errorList)">
            <div :key="index" v-if="isObjectArray(errorList[error])">
              <strong>{{ error }}:</strong>
              <p :key="ind" v-for="(err, ind) in errorList[error]">{{ err }}</p>
              <!-- <p :key="ind" v-for="(err, ind) in errorList[error]">{{ err }}</p> -->
            </div>
            <div :key="index" v-else>
              <strong>{{ error }}:</strong>
              <p :key="ind" >{{ errorList[error] }}</p>
            </div>
          </template>
        </b-alert>
      </div>
      <div>
        <b-button
          :disabled="!isForgotPassFormValid"
          class="mt-3"
          block
          variant="primary"
          @click="forgotPassword()"
          >Submit</b-button
        >
      </div>
    </b-modal>

    <b-modal id="register" ref="register" hide-footer title="Register">
      <div>
        <b-form-group label="Username">
          <b-form-input
            v-model="registerCredentials.username"
            trim
          ></b-form-input>
        </b-form-group>
      </div>
      <div>
        <b-form-group label="Email">
          <b-form-input v-model="registerCredentials.email" trim></b-form-input>
        </b-form-group>
      </div>
      <div>
        <b-form-group label="First Name">
          <b-form-input
            v-model="registerCredentials.first_name"
            trim
          ></b-form-input>
        </b-form-group>
      </div>
      <div>
        <b-form-group label="Last Name">
          <b-form-input
            v-model="registerCredentials.last_name"
            trim
          ></b-form-input>
        </b-form-group>
      </div>
      <div>
        <b-form-group label="Password">
          <b-form-input
            type="password"
            v-model="registerCredentials.password"
            trim
          ></b-form-input>
        </b-form-group>
      </div>
      <div>
        <b-form-group label="Confirm Password">
          <b-form-input
            type="password"
            v-model="registerCredentials.password2"
            trim
          ></b-form-input>
        </b-form-group>
      </div>
      <div>
        <VueRecaptcha
          sitekey="6LesJ4QcAAAAAGPIxTSBdGTQKfKzrNO4I68p2wD1"
          :loadRecaptchaScript="true"
          @verify="validate"
        />
        <!-- <vue-recaptcha sitekey="6LesJ4QcAAAAAGPIxTSBdGTQKfKzrNO4I68p2wD1"> -->
        <!-- <button>Click me</button> -->
        <!-- </vue-recaptcha> -->
        <!-- <vue-recaptcha sitekey="6LesJ4QcAAAAAGPIxTSBdGTQKfKzrNO4I68p2wD1"></vue-recaptcha> -->
      </div>
      <div class="error-message" v-if="Object.keys(errorList).length">
        <b-alert show variant="danger">
          <template v-for="(error, index) in Object.keys(errorList)">
            <div :key="index">
              <strong>{{ error }}:</strong>
              <p :key="ind" v-for="(err, ind) in errorList[error]">{{ err }}</p>
            </div>
          </template>
        </b-alert>
      </div>
      <div>
        <b-button
          :disabled="!isRegisterFormValid"
          class="mt-3"
          block
          variant="primary"
          @click="register()"
          >Register</b-button
        >
      </div>
    </b-modal>
  </div>
</template>
<script>
import axios from "axios";
import VueRecaptcha from "vue-recaptcha";
// import Validation from '@/services/recaptchaValidate'

export default {
  name: "Header",
  components: { VueRecaptcha },

  data() {
    return {
      loginCredentials: {
        username: "",
        password: "",
      },
      forgotPassewordCredentials: {
        email: "",
      },
      registerCredentials: {
        username: "",
        email: "",
        password: "",
        password2: "",
        first_name: "",
        last_name: "",
        recaptcha_response_status: null,
      },
      errorList: {},
    };
  },
  computed: {
    
    isLoggedIn() {
      return this.$store.state.isUserLoggedIn;
    },
    isForgotPassFormValid() {
      return (
        this.forgotPassewordCredentials.email !== ""
      );
    },
    isLoginFormValid() {
      return (
        this.loginCredentials.username !== "" &&
        this.loginCredentials.password !== ""
      );
    },
    isRegisterFormValid() {
      return (
        this.registerCredentials.username !== "" &&
        this.registerCredentials.password !== "" &&
        this.registerCredentials.password2 !== "" &&
        this.registerCredentials.password ===
          this.registerCredentials.password2 &&
        this.registerCredentials.password.length > 8 &&
        this.registerCredentials.email !== "" &&
        this.registerCredentials.first_name !== "" &&
        this.registerCredentials.last_name !== ""
      );
    },
  },
  methods: {
    isObjectArray(data) {
      // console.log(data)
      if(Array.isArray(data)){
        return true;
      }
      else{
        return false;
      }
    },
    // For recaptcha
    validate(response) {
      // send an axios request post to http://127.0.0.1:8000/is_recaptcha_valid/ with values g-recaptcha-response having response getting in this function
      // then on cheking status message is success, let the user register the account else show the alert message of captcha not validated
      //
      // console.log(response);
      var formData = new FormData();
      formData.append("g-recaptcha-response", response);

      axios
        .post(
          `${process.env.VUE_APP_API_URL}/is_recaptcha_valid/`,
          formData
          // response
          // `${process.env.VUE_APP_API_URL}/api-token-auth/`,
          // this.loginCredentials
        )
        .then((res) => {
          // console.log(res.data);
          this.registerCredentials.recaptcha_response_status = res.data;
        })
        .catch((err) => {
          this.errorList = err.response.data;
        });

      // Validation.validate({Response: response}).then(result => {
      //   this.$emit('validate', result.objectResult.success)
      // }).catch(error => console.log(error))
    },
    forgotPassModal(){
          this.$refs["login"].hide();
          this.$refs["forgotPassword"].show();
    },
    forgotPassword(){
      // console.log('email is')
      // console.log(this.forgotPassewordCredentials)
         axios
        .post(
          `${process.env.VUE_APP_API_URL}/api-auth/password/reset/`, 
          // `${process.env.VUE_APP_API_URL}/api-token-auth/`,
          this.forgotPassewordCredentials
        )
        .then((res) => {
          // console.log(res.data);
          // this.errorList = {};
          this.$refs["forgotPassword"].hide();
        })
        .catch((err) => {
          this.errorList = err.response.data;
        });
    },
    login() {
      axios
        .post(
          `${process.env.VUE_APP_API_URL}/api-token-auth-custom/`, 
          // `${process.env.VUE_APP_API_URL}/api-token-auth/`,
          this.loginCredentials
        )
        .then((res) => {
          // console.log(res.data);
          this.errorList = {};
          localStorage.setItem("BOOK_APP_TOKEN", "Token " + res.data.token);
          localStorage.setItem("BOOK_APP_USERNAME", res.data.username);
          localStorage.setItem("BOOK_APP_USER_ID", res.data.user_id);
          // console.log("res.data.username");
          // console.log(res.data.username);
          this.$store.commit("UPDATE_TOKEN", "Token " + res.data.token);
          this.$store.commit("UPDATE_USERNAME", res.data.username);
          this.$store.commit("UPDATE_USER_ID", res.data.user_id);
          this.$store.commit("UPDATE_LOGIN_STATUS", true);
          this.$refs["login"].hide();
          this.getTopicList(true);
          // console.log(this.$store.state.token);
          // console.log(this.$store.state.username);
          // console.log(this.$store.state.user_id);
        })
        .catch((err) => {
          this.errorList = err.response.data;
        });
    },
    logout() {
      this.$store.commit("UPDATE_ACTIVITY_LIST", []);
      localStorage.removeItem("BOOK_APP_TOKEN");
      this.$store.commit("UPDATE_TOKEN", "");
      this.$store.commit("UPDATE_USERNAME", "");
      this.$store.commit("UPDATE_LOGIN_STATUS", false);
      this.getTopicList(false);
    },
    getTopicList(flag) {
      let config = {};
      if (flag) {
        config = {
          headers: {
            authorization: `${this.$store.state.token}`,
          },
        };
      }
      axios
        .get(`${process.env.VUE_APP_API_URL}/topics/`, config)
        .then((res) => {
          this.$store.commit("UPDATE_ACTIVITY_LIST", res.data);
        });
    },
    register() {
      if (
        this.registerCredentials.recaptcha_response_status !== null &&
        this.registerCredentials.recaptcha_response_status.result.success
      ) {
        axios
          .post(
            `${process.env.VUE_APP_API_URL}/register/`,
            this.registerCredentials
          )
          .then((res) => {
            this.errorList = {};
            this.$refs["register"].hide();
            this.$refs["login"].show();
          })
          .catch((err) => {
            // console.log(err.response.data)
            this.errorList = err.response.data;
          });
      } else {
        var error = {'Error':['ReCaptcha Failed']};
        this.errorList = error;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.header {
  background: $primary;
  .menu {
    justify-content: space-between;
    align-items: center;
    color: #fff;
    height: 50px;
    .brand {
      font-weight: bold;
    }
    .login {
      margin-bottom: 0;
    }
  }
}
</style>