<template>
  <div class="node" :id="topic.id" v-if="topic.id">
    <Drag
      v-if="isLoggedIn && isAuthenticated"
      :transferData="topic"
      @drop="myListener(topic, ...arguments)"
    >
      <img class="drag-handle" src="@/assets/img/dots.svg" alt="" />
    </Drag>

    <Drop @drop="handleDrop(topic, ...arguments)">
      <b-card :title="topic.name" @click="ChangeSelectedIndex()" >
        <b-card-text>
          <img
            class="icon topRight"
            @click="pinNode(topic, siblings, topicIndex)"
            src="@/assets/img/pin.svg"
          />
          <img
            v-if="isLoggedIn && isAuthenticated"
            class="icon bottomRight"
            @click="createNewTopic(topic)"
            src="@/assets/img/create.svg"
          />
          <img class="icon bottomLeft" src="@/assets/img/zoomIn.svg" />
          <img
            class="icon topLeft"
            @click="setAsParent(topic)"
            src="@/assets/img/arrowUp.svg"
          />
        </b-card-text>
      </b-card>
    </Drop>
    <b-modal
      id="modal-1"
      ref="createTopicModal"
      hide-footer
      title="Create Topic"
    >
      <div>
        <div>
          <div class="breadcrumb-container mb-2 grey-bg">
            <b-breadcrumb>
              <b-breadcrumb-item>{{ parentTopic.name }}</b-breadcrumb-item>
            </b-breadcrumb>
          </div>
          <b-form-group id="fieldset-1" label="Enter Title">
            <b-form-input
              id="input-1"
              v-model="newTopic.name"
              trim
            ></b-form-input>
          </b-form-group>
        </div>
        <div>
          <b-form-group id="fieldset-1" label="Type">
            <b-form-select v-model="newTopic.type">
              <template #first>
                <b-form-select-option :value="null" disabled
                  >-- Topic Type --</b-form-select-option
                >
              </template>
              <b-form-select-option
                :key="index"
                v-for="(type, index) in typeList"
                :value="type.value"
                >{{ type.label }}</b-form-select-option
              >
            </b-form-select>
          </b-form-group>
        </div>

        <b-button class="mt-3" block variant="primary" @click="saveTopic()"
          >Save</b-button
        >
      </div>
    </b-modal>
    <b-modal hide-footer ref="confirmationMessage" title="Update Confirmation">
      <p class="my-4">Are you Sure you want to update?</p>
      <b-button variant="primary" @click="updateParentNode()">Update</b-button
      >&nbsp;&nbsp;
      <b-button @click="closeModal()">Cancel</b-button>
    </b-modal>
  </div>
</template>
<script>
import axios from "axios";
import { Drag, Drop } from "vue-drag-drop";

export default {
  name: "TreeNode",
  components: { Drag, Drop },
  inheritAttrs: false,
  props: ["topic", "active", "changeCollapsed", "siblings", "topicIndex"],
  data() {
    return {
      newTopic: {
        name: "",
        type: "",
      },
      draggedTopic: {},
      parentTopic: {},
      typeList: [
        { label: "Diary", value: "diary" },
        { label: "Literary Novel", value: "literary_novel" },
        { label: "Course", value: "course" },
        { label: "Campaign", value: "campaign" },
        { label: "General Topic", value: "General_topic" },
        { label: "Faq Topic", value: "faq_topic" },
        { label: "Customer Hierarchy", value: "customer_hierarchy" },
      ],
    };
  },
  computed: {
    topicTree() {
      return this.$store.state.topicTree;
    },
    isLoggedIn() {
      return this.$store.state.isUserLoggedIn;
    },
    isAuthenticated() {
      return this.topic.authenticated;
    },
  },
  methods: {
    ChangeSelectedIndex(){
      if(this.topicIndex!=undefined){
        // this.$store.commit("UPDATE_SELECTED_INDEX", this.topic.id);
        // this.$store.commit("UPDATE_SELECTED_INDEX", this.topicIndex);
        this.$store.commit("UPDATE_SELECTED_INDEX_OBJECT", {topicIndex:this.topicIndex,key:this.topic.parent});
      }
    },
    myListener(myArg, transferData, nativeEvent) {
      console.log("myListener");
    },
    handleDrop(parent, child, nativeEvent) {
      if (this.isLoggedIn && parent.id !== child.id) {
        this.draggedTopic = {
          ...child,
          parent: parent.id,
        };
        this.$refs["confirmationMessage"].show();
      }
    },
    closeModal() {
      this.$refs["confirmationMessage"].hide();
    },
    updateParentNode() {
      const config = {
        headers: {
          authorization: `${this.$store.state.token}`,
        },
      };
      axios
        .put(
          `${process.env.VUE_APP_API_URL}/topics/${this.draggedTopic.id}/`,
          this.draggedTopic,
          config
        )
        .then(() => {
          this.closeModal();
          if (this.topicTree.id) {
            this.setAsParent(this.topicTree);
          }
        })
        .catch((e) => {
          this.closeModal();
          let keys = Object.keys(e.response.data);
          this.$bvToast.toast(`${e.response.data[keys[0]]}`, {
            title: "Warning",
            autoHideDelay: 10000,
            visible: true,
            variant: "warning",
            appendToast: true,
          });
        });
    },
    toggleCollapse() {
      // this.changeCollapsed(false);
    },

    async setAsParent(topic) {
      let node = await this.getData(topic.id);
      this.$store.commit("UPDATE_TOPIC_TREE_LIST", node);
    },
    async getData(id) {
      try {
        // this.$store.commit("UPDATE_LOADER", true);
        const config = {
          headers: {
            authorization: `${this.$store.state.token}`,
          },
        };
        let response = await axios.get(
          `${process.env.VUE_APP_API_URL}/topics/${id}/`,
          config
        );
        // this.$store.commit("UPDATE_LOADER", false);
        response.data.subtopics.forEach(async (topic, index) => {
          response.data.subtopics[index] = await this.getData(topic.id);
        });
        this.$store.commit("UPDATE_TREE", 0);
        return response.data;
      } catch (err) {
        // this.$store.commit("UPDATE_LOADER", false);
        return [];
      }
    },
    createNewTopic(topic) {
      this.parentTopic = topic;
      this.$refs["createTopicModal"].show();
    },
    saveTopic() {
      const config = {
        headers: {
          authorization: `${this.$store.state.token}`,
        },
      };
      axios
        .post(
          `${process.env.VUE_APP_API_URL}/topics/`,
          {
            name: this.newTopic.name,
            type: this.newTopic.type,
            parent: this.parentTopic.id,
          },
          config
        )
        .then(() => {
          this.newTopic = {
            name: "",
            type: "",
          };
          this.$refs["createTopicModal"].hide();
          if (this.topicTree.id) {
            this.setAsParent(this.topicTree);
          }
        });
    },
    getTopicData(id) {
      const config = {
        headers: {
          authorization: `${this.$store.state.token}`,
        },
      };
      axios
        .get(`${process.env.VUE_APP_API_URL}/topics/${id}/`, config)
        .then((res) => {
          this.$store.commit("UPDATE_TOPIC_TREE_LIST", res.data);
        });
    },
    pinNode(node, siblings, topicIndex) {
      // console.log('node',node)
      // console.log('siblings',siblings)
      // console.log('topicIndex',topicIndex)
      if (siblings) {
        if (!siblings[topicIndex].subtopics) {
          siblings[topicIndex].subtopics = []; 
        }
        if (!siblings[topicIndex].explanations) {
          siblings[topicIndex].explanations = [];
        }
      }
      this.$store.commit("UPDATE_ACTIVE_INDEX", topicIndex ? topicIndex : 0);
      this.$store.commit("UPDATE_ACTIVITY_LIST", siblings ? siblings : [node]);
      this.$store.commit("UPDATE_PARENT", node.parent);    // added line for movinglevelup after node 22 Dec
      this.$router.push("/");
    },
  },
};
</script>
<style lang="scss" scoped>
.node {
  position: relative;
  .card {
    &.selected {
      background-color: $primary;
      color: #fff;
    }
    height: 150px;
    width: 250px;
    text-align: center;
    .card-body {
      .card-title {
        font-weight: bold;
        text-transform: capitalize;
      }
      display: flex;
      justify-content: center;
      align-items: center;
      .icon {
        position: absolute;
        cursor: pointer;
        &.topRight {
          top: 10px;
          right: 10px;
        }
        &.topLeft {
          top: 10px;
          left: 10px;
        }
        &.bottomRight {
          bottom: 10px;
          right: 10px;
        }
        &.bottomLeft {
          bottom: 10px;
          left: 10px;
        }
      }
    }
  }
  img.drag-handle {
    display: none;
    position: absolute;
    height: 20px;
    left: 50%;
    top: 12px;
    cursor: grab;
    z-index: 9999999;
  }
  &:hover,
  &:focus {
    img.drag-handle {
      display: block;
    }
  }
}
</style>