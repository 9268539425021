import { ADD_NAME,UPDATE_USER_EMAILS, UPDATE_ACTIVE_INDEX,UPDATE_PARENT, UPDATE_ACTIVE_ITEM, UPDATE_ACTIVITY_LIST, UPDATE_LOADER, UPDATE_LOGIN_STATUS, UPDATE_SLIDER_SIZE,UPDATE_SELECTED_INDEX,UPDATE_SELECTED_INDEX_OBJECT, UPDATE_TOKEN,UPDATE_USER_ID,UPDATE_USERNAME, UPDATE_TOPIC_TREE_LIST, UPDATE_TREE, UPDATE_SLUG_ID, UPDATE_SLUG, UPDATE_EXPLANATION_TITLE_OPTIONS } from '../../mutations-type'

export default {
  [ADD_NAME](state, payload) {
    state.name = payload
  },

  [UPDATE_LOGIN_STATUS](state, payload) {
    state.isUserLoggedIn = payload
  },

  [UPDATE_LOGIN_STATUS](state, payload) {
    state.isUserLoggedIn = payload
  },

  [UPDATE_TOKEN](state, payload) {
    state.token = payload
  },

  [UPDATE_USERNAME](state, payload) {
    state.username = payload
  },
  [UPDATE_USER_ID](state, payload) {
    state.user_id = payload
  },
  [UPDATE_USER_EMAILS](state, payload) {
    state.user_emails = payload
  },

  [UPDATE_EXPLANATION_TITLE_OPTIONS](state, payload) {
    state.explanation_title_choices = payload
  },

  [UPDATE_ACTIVITY_LIST](state, payload) {
    state.topicList = payload
  },
  [UPDATE_TOPIC_TREE_LIST](state, payload) {
    state.topicTree = payload
  },

  [UPDATE_ACTIVE_INDEX](state, payload) {
    state.activeIndex = payload
  },

  [UPDATE_PARENT](state, payload) {
    state.parent = payload
  },

  [UPDATE_ACTIVE_ITEM](state, payload) {
    state.activeItem = payload
  },
  [UPDATE_SLIDER_SIZE](state, payload) {
    state.sliderSize = payload
  },
  [UPDATE_SELECTED_INDEX](state, payload) {
    state.selectedIndex = payload
  },
  [UPDATE_SELECTED_INDEX_OBJECT](state, payload) {
    var newObject = JSON.parse(JSON.stringify(state.selectedIndexObject));
    newObject[payload.key] = payload.topicIndex
    state.selectedIndexObject = newObject
  },
  [UPDATE_TREE](state, payload) {
    state.treeUpdater = Math.random()
  },
  [UPDATE_LOADER](state, payload) {
    state.showLoader = payload
  },

  [UPDATE_SLUG_ID](state, payload) {
    state.slugTopicId = payload
  },
  [UPDATE_SLUG](state, payload) {
    state.slug = payload
  },

}

