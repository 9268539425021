<template>
  <div class="home" :class="isMobile ? 'mobile' : ''">
    <br />
    <h1>Our Terms of Use</h1>
    <div class="content pb-5">
      <!-- <div class="row" key="updateHorizontalSlider"></div> -->
      <div class="pt-2 mb-5">
        <div class="row">
          <div class="sub-topic-content mt-5">
            <!-- <h4 class="title">Terms and Conditions</h4> -->
            <b-card>
              <b-card-text>
                <p>Welcome to TopNFlow!</p>
                <p>
                  We welcome you (“you” or the “user”) as a reader, editor,
                  author, or contributor to TopNFlow. Before you participate,
                  however, we ask that you please read and agree to the
                  following Terms of Use (“Terms of Use”).
                </p>
                <p>
                  This TopNFlow User Agreement (“ <strong>Terms</strong>”)
                  applies to your access to and use of the websites, mobile
                  apps, widgets, APIs, emails, and other online products and
                  services (collectively, the “<strong>Services</strong>”)
                  provided by Chicmomo, Inc.
                  (“<strong>TopNFlow</strong>”,”<strong>Chicmomo</strong>”
                  “<strong>we</strong>,” “<strong>us</strong>,” or
                  “<strong>our</strong>”, “<strong>company</strong>”).
                </p>
                <p>
                  By accessing or using our Services, you agree to be bound by
                  these Terms. If you do not agree to these Terms, you may not
                  access or use our Services.
                </p>
                <p>
                  Please take a look at TopNFlow’s
                  <a href="/privacypolicy"> Privacy Policy</a> too—it explains
                  how and why we collect, use, and share information about you
                  when you access or use our Services.
                </p>
                <h4>1. Your Access to the Services</h4>
                <p>
                  Children under the age of 13 are not allowed to create an
                  Account or otherwise use the Services. Additionally, you must
                  be over the age required by the laws of your country to create
                  an account or otherwise use the Services, or we need to have
                  received verifiable consent from your parent or legal
                  guardian.
                </p>
                <p>
                  In addition, certain of our Services or portions of our
                  Services require you to be 18 years of age or older, so please
                  read all notices and any Additional Terms carefully when you
                  access the Services.
                </p>
                <p>
                  If you are accepting these Terms on behalf of another legal
                  entity, including a business or government entity, you
                  represent that you have full legal authority to bind such
                  entity to these Terms.
                </p>
                <h4>2. Your Use of the Services</h4>
                <p>
                  TopNFlow grants you a personal, non-transferable,
                  non-exclusive, revocable, limited license to use and access
                  the Services solely as permitted by these Terms. We reserve
                  all rights not expressly granted to you by these Terms.
                </p>
                <p>
                  Except as permitted through the Services or as otherwise
                  permitted by us in writing, your license does not include the
                  right to:
                </p>
                <ul>
                  <li>
                    license, sell, transfer, assign, distribute, host, or
                    otherwise commercially exploit the Services or Content;
                  </li>
                  <li>
                    modify, prepare derivative works of, disassemble, decompile,
                    or reverse engineer any part of the Services or Content; or
                  </li>
                  <li>
                    access the Services or Content in order to build a similar
                    or competitive website, product, or service, except as
                    permitted under these terms.
                  </li>
                </ul>
                <p>
                  We reserve the right to modify, suspend, or discontinue the
                  Services (in whole or in part) at any time, with or without
                  notice to you. Any future release, update, or other addition
                  to functionality of the Services will be subject to these
                  Terms, which may be updated from time to time. You agree that
                  we will not be liable to you or to any third party for any
                  modification, suspension, or discontinuation of the Services
                  or any part thereof.
                </p>
                <h4>3. Your TopNFlow Account and Account Security</h4>
                <p>
                  To use certain features of our Services, you may be required
                  to create a TopNFlow account (an “<strong>Account</strong>”)
                  and provide us with a username, password, and certain other
                  information about yourself as set forth in the
                  <a href="/privacypolicy">Privacy Policy</a>.
                </p>
                <p>
                  You are solely responsible for the information associated with
                  your Account and anything that happens related to your
                  Account. You must maintain the security of your Account and
                  immediately <a href="/contact-us">notify TopNFlow </a> if you
                  discover or suspect that someone has accessed your Account
                  without your permission.
                </p>
                <p>
                  You will not license, sell, or transfer your Account without
                  our prior written approval.
                </p>
                <h4>4. Your Content</h4>
                <p>
                  The Services may contain information, text, links, graphics,
                  photos, videos, or other materials
                  (“<strong>Content</strong>”), including Topics, sub topics,
                  tiles or explanations created or submitted to the Services by
                  you or through your Account (“<strong>Your Content</strong>”).
                  We take no responsibility for and we do not expressly or
                  implicitly endorse, support, or guarantee the completeness,
                  truthfulness, accuracy, or reliability of any of Your Content
                  or any content posted by any other user.
                </p>
                <p>
                  By submitting Your Content to the Services, you represent and
                  warrant that you have all rights, power, and authority
                  necessary to grant the rights to Your Content contained within
                  these Terms. Because you alone are responsible for Your
                  Content, you may expose yourself to liability if you post or
                  share Content without all necessary rights.
                </p>
                <p>
                  You retain any ownership rights you have in Your Content, but
                  you grant TopNFlow the following license to use that Content:
                </p>
                <p>
                  When Your Content is created with or submitted to the
                  Services, you grant us a worldwide, royalty-free, perpetual,
                  irrevocable, non-exclusive, transferable, and sublicensable
                  license to use, copy, modify, adapt, prepare derivative works
                  of, distribute, store, perform, and display Your Content and
                  any name, username, voice, or likeness provided in connection
                  with Your Content in all media formats and channels now known
                  or later developed anywhere in the world. This license
                  includes the right for us to make Your Content available for
                  syndication, broadcast, distribution, or publication by other
                  companies, organizations, or individuals who partner with
                  TopNFlow. You also agree that we may remove metadata
                  associated with Your Content, and you irrevocably waive any
                  claims and assertions of moral rights or attribution with
                  respect to Your Content.
                </p>
                <p>
                  Any ideas, suggestions, and feedback about TopNFlow or our
                  Services that you provide to us are entirely voluntary, and
                  you agree that TopNFlow may use such ideas, suggestions, and
                  feedback without compensation or obligation to you.
                </p>
                <p>
                  Although we have no obligation to screen, edit, or monitor
                  Your Content, we may, in our sole discretion, delete or remove
                  Your Content at any time and for any reason, including for
                  violating these Terms, violating these terms and conditions of
                  use, or if you otherwise create or are likely to create
                  liability for us.
                </p>
                <h4>5. Third-Party Content, Advertisements, and Promotions</h4>
                <p>
                  The Services may contain links to third-party websites,
                  products, or services, which may be posted by advertisers, our
                  affiliates, our partners, or other users (“<strong
                    >Third-Party Content</strong
                  >”). Third-Party Content is not under our control, and we are
                  not responsible for any third party’s websites, products, or
                  services. Your use of Third-Party Content is at your own risk
                  and you should make any investigation you feel necessary
                  before proceeding with any transaction in connection with such
                  Third-Party Content.
                </p>
                <p>
                  The Services may also contain sponsored Third-Party Content or
                  advertisements. The type, degree, and targeting of
                  advertisements are subject to change, and you acknowledge and
                  agree that we may place advertisements in connection with the
                  display of any Content or information on the Services,
                  including Your Content.
                </p>
                <p>
                  If you choose to use the Services to conduct a promotion,
                  including a contest or sweepstakes
                  (“<strong>Promotion</strong>”), you alone are responsible for
                  conducting the Promotion in compliance with all applicable
                  laws and regulations at your own risk. Your Promotion must
                  state that the Promotion is not sponsored by, endorsed by, or
                  associated with TopNFlow, and the rules for your Promotion
                  must require each entrant or participant to release TopNFlow
                  from any liability related to the Promotion.
                </p>
                <h4>6. Things You Cannot Do</h4>
                <p>
                  When using or accessing TopNFlow, you must comply with these
                  Terms and all applicable laws, rules, and regulations. We use
                  the same content policies as described by popular communities
                  and forum website Reddit. Please review the
                  <a href="https://www.redditinc.com/policies/content-policy"
                    >Content Policy</a
                  >
                  (and for RPAN, the
                  <a
                    href="https://www.redditinc.com/policies/broadcasting-content-policy"
                    >Broadcasting Content Policy</a
                  >), which are part of these Terms. TopNFlow’s follows same
                  rules about prohibited content and conduct. In addition to
                  what is prohibited in the Reddit
                  <a href="https://www.redditinc.com/policies/content-policy">
                    Content Policy </a
                  >, you may not do any of the following:
                </p>
                <ul>
                  <li>
                    Use the Services in any manner that could interfere with,
                    disable, disrupt, overburden, or otherwise impair the
                    Services.
                  </li>
                  <li>
                    Gain access to (or attempt to gain access to) another user’s
                    Account or any non-public portions of the Services,
                    including the computer systems or networks connected to or
                    used together with the Services.
                  </li>
                  <li>
                    Upload, transmit, or distribute to or through the Services
                    any viruses, worms, malicious code, or other software
                    intended to interfere with the Services, including its
                    security-related features.
                  </li>
                  <li>
                    Use the Services to violate applicable law or infringe any
                    person’s or entity's intellectual property rights or any
                    other proprietary rights.
                  </li>
                  <li>
                    Access, search, or collect data from the Services by any
                    means (automated or otherwise) except as permitted in these
                    Terms or in a separate agreement with TopNFlow. We
                    conditionally grant permission to crawl the Services in
                    accordance with the parameters set forth in our robots.txt
                    file, but scraping the Services without TopNFlow’s prior
                    consent is prohibited.
                  </li>
                  <li>
                    Use the Services in any manner that we reasonably believe to
                    be an abuse of or fraud on TopNFlow or any payment system.
                  </li>
                </ul>
                <p>
                  We encourage you to report content or conduct that you believe
                  violates these Terms.
                </p>
                <h4>7. Subject Matter Expert Editors</h4>
                <p>
                  Making or editing a subTopNFlow is voluntary position that may
                  be available to users of the Services by invitation. The
                  topics marked by some users as public may also be available
                  for edition. We are not responsible for actions taken by the
                  subject matter experts or moderators of public topics. We
                  reserve the right to revoke or limit a user’s ability to
                  moderate at any time and for any reason or no reason,
                  including for a breach of these Terms.
                </p>
                <p>If you choose to moderate a topic creation on TopNFlow:</p>
                <ul>
                  <li>
                    You agree that when you receive reports related to a
                    subTopNFlow you moderate, you will take appropriate action,
                    which may include removing content that violates policy
                    and/or promptly escalating to TopNFlow for review;
                  </li>
                  <li>
                    You are not, and may not represent that you are, authorized
                    to act on behalf of TopNFlow.
                  </li>
                  <li>
                    You may not enter into any agreement with a third party on
                    behalf of TopNFlow, or any subTopNFlows that you moderate,
                    without our written approval;
                  </li>
                  <li>
                    You may not perform moderation actions in return for any
                    form of compensation, consideration, gift, or favor from
                    third parties;
                  </li>
                  <li>
                    If you have access to non-public information as a result of
                    moderating a TopNFlow topics or sub topics or other
                    explanations, you will use such information only in
                    connection with your performance as a moderator and expert
                    in the topic of discussion.
                  </li>
                  <li>
                    You may create and add to the topics as an expert moderator.
                  </li>
                </ul>
                <p>
                  TopNFlow reserves the right, but has no obligation, to
                  overturn any action or decision of an expert or general
                  editors of topics if TopNFlow, in its sole discretion,
                  believes that such action or decision is not in the interest
                  of TopNFlow or the TopNFlow community.
                </p>

                <h4>8. Copyright, Trademark, the DMCA, and Takedowns</h4>
                <p>
                  TopNFlow respects the intellectual property of others and
                  requires that users of our Services do the same. We have a
                  policy that includes the removal of any infringing material
                  from the Services and for the termination, in appropriate
                  circumstances, of users of our Services who are repeat
                  infringers. If you believe that anything on our Services
                  infringes a copyright or a trademark that you own or control,
                  you may notify TopNFlow’s Designated Agent by filling out by
                  <a href="/contact-us">contacting us</a>.
                </p>
                <p>
                  Also, please note that if you knowingly misrepresent that any
                  activity or material on our Service is infringing, you may be
                  liable to TopNFlow for certain costs and damages.
                </p>
                <p>
                  If we remove Your Content in response to a copyright or
                  trademark notice, we will notify you via your registered
                  account email. If you believe Your Content was wrongly removed
                  due to a mistake or misidentification in a copyright notice,
                  you can send a counter notification by
                  <a href="/contact-us">contacting us</a>..
                </p>
                <h4>9. Paid Services and Payment Information</h4>
                <p>
                  Some services, including TopNFlow Premium and Virtual Goods,
                  may be available for purchase (“<strong>Paid Services</strong
                  >”). In addition to these Terms, by purchasing or using
                  TopNFlow Premium or our Virtual Goods.
                </p>
                <p>
                  TopNFlow may change the fees or benefits associated with the
                  Paid Services from time to time with reasonable advance notice
                  of material changes; provided, however, that no advance notice
                  will be required for temporary promotions, including temporary
                  reductions in the fees associated with the Paid Services.
                </p>
                <p>
                  You may submit your debit card, credit card, or other payment
                  information (“<strong>Payment Information</strong>”) via our
                  Services to purchase the Paid Services. We use third-party
                  service providers to process your Payment Information. If you
                  submit your Payment Information, you agree to pay all costs
                  that you incur, and you give us permission to charge you when
                  payment is due for an amount that includes these costs and any
                  applicable taxes and fees.
                </p>
                <h4>10. Indemnity</h4>
                <p>
                  Except to the extent prohibited by law, you agree to defend,
                  indemnify, and hold us, our directors, officers, employees,
                  affiliates, agents, contractors, third-party service
                  providers, and licensors (the “<strong
                    >TopNFlow Entities</strong
                  >”) harmless from any claim or demand, including costs and
                  attorneys’ fees, made by any third party due to or arising out
                  of (a) your use of the Services, (b) your violation of these
                  Terms, (c) your violation of applicable laws or regulations,
                  or (d) Your Content. We reserve the right to control the
                  defense of any matter for which you are required to indemnify
                  us, and you agree to cooperate with our defense of these
                  claims.
                </p>
                <h4>11. Disclaimers</h4>
                <p>
                  THE SERVICES ARE PROVIDED “AS IS” AND “AS AVAILABLE” WITHOUT
                  WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING,
                  BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY,
                  TITLE, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT.
                  TOPNFLOW ENTITIES DO NOT WARRANT THAT THE SERVICES ARE
                  ACCURATE, COMPLETE, RELIABLE, CURRENT, OR ERROR FREE. TOPNFLOW
                  DOES NOT CONTROL, ENDORSE, OR TAKE RESPONSIBILITY FOR ANY
                  CONTENT AVAILABLE ON OR LINKED TO THE SERVICES OR THE ACTIONS
                  OF ANY THIRD PARTY OR USER, INCLUDING MODERATORS. WHILE
                  TOPNFLOW ATTEMPTS TO MAKE YOUR ACCESS TO AND USE OF OUR
                  SERVICES SAFE, WE DO NOT REPRESENT OR WARRANT THAT OUR
                  SERVICES OR SERVERS ARE FREE OF VIRUSES OR OTHER HARMFUL
                  COMPONENTS.
                </p>
                <h4>12. Limitation of Liability</h4>
                <p>
                  IN NO EVENT AND UNDER NO THEORY OF LIABILITY, INCLUDING
                  CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY, WARRANTY, OR
                  OTHERWISE, WILL THE TOPNFLOW ENTITIES BE LIABLE TO YOU FOR ANY
                  INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR
                  PUNITIVE DAMAGES, OR LOST PROFITS ARISING FROM OR RELATING TO
                  THESE TERMS OR THE SERVICES, INCLUDING THOSE ARISING FROM OR
                  RELATING TO CONTENT MADE AVAILABLE ON THE SERVICES THAT IS
                  ALLEGED TO BE DEFAMATORY, OFFENSIVE, OR ILLEGAL. ACCESS TO,
                  AND USE OF, THE SERVICES IS AT YOUR OWN DISCRETION AND RISK,
                  AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR
                  DEVICE OR COMPUTER SYSTEM, OR LOSS OF DATA RESULTING
                  THEREFROM. IN NO EVENT WILL THE AGGREGATE LIABILITY OF THE
                  TOPNFLOW ENTITIES EXCEED THE GREATER OF ONE HUNDRED U.S.
                  DOLLARS ($100) OR ANY AMOUNT YOU PAID TOPNFLOW IN THE PREVIOUS
                  SIX MONTHS FOR THE SERVICES GIVING RISE TO THE CLAIM. THE
                  LIMITATIONS OF THIS SECTION WILL APPLY TO ANY THEORY OF
                  LIABILITY, INCLUDING THOSE BASED ON WARRANTY, CONTRACT,
                  STATUTE, TORT (INCLUDING NEGLIGENCE) OR OTHERWISE, AND EVEN IF
                  THE TOPNFLOW ENTITIES HAVE BEEN ADVISED OF THE POSSIBILITY OF
                  ANY SUCH DAMAGE, AND EVEN IF ANY REMEDY SET FORTH HEREIN IS
                  FOUND TO HAVE FAILED ITS ESSENTIAL PURPOSE. THE FOREGOING
                  LIMITATION OF LIABILITY WILL APPLY TO THE FULLEST EXTENT
                  PERMITTED BY LAW IN THE APPLICABLE JURISDICTION.
                </p>
                <h4>13. Governing Law and Venue</h4>
                <p>
                  We want you to enjoy TopNFlow, so if you have an issue or
                  dispute, you agree to raise it and try to resolve it with us
                  informally. You can contact us with feedback and concerns by
                  <a href="/contact-us">contacting us</a>.
                </p>
                <p>
                  Except for the government entities listed below, any claims
                  arising out of or relating to these Terms or the Services will
                  be governed by the laws of New York, without regard to its
                  conflict of laws rules. All disputes related to these Terms or
                  the Services will be brought solely in the federal or state
                  courts located in New York, and you consent to personal
                  jurisdiction in these courts.
                </p>
                <h5>Government Entities</h5>
                <p>
                  If you are a U.S. city, county, or state government entity,
                  then this Section 13 does not apply to you.
                </p>
                <p>
                  If you are a U.S. federal government entity, any claims
                  arising out of or relating to these Terms or the Services will
                  be governed by the laws of the United States of America
                  without regard to its conflict of laws rules. To the extent
                  permitted by federal law, the laws of California (other than
                  its conflict of law rules) will apply in the absence of
                  applicable federal law. All disputes related to these Terms or
                  the Services will be brought solely in the federal or state
                  courts located in New York.
                </p>
                <h4>14. Changes to these Terms</h4>
                <p>
                  We may make changes to these Terms from time to time. If we
                  make changes, we will post the revised Terms and update the
                  Effective Date above. If the changes, in our sole discretion,
                  are material, we may also notify you by sending an email to
                  the address associated with your Account (if you have chosen
                  to provide an email address) or by otherwise providing notice
                  through our Services. By continuing to access or use the
                  Services on or after the Effective Date of the revised Terms,
                  you agree to be bound by the revised Terms. If you do not
                  agree to the revised Terms, you must stop accessing and using
                  our Services before the changes become effective.
                </p>
                <h4>15. Additional Terms</h4>
                <p>
                  Because we offer a variety of Services, you may be asked to
                  agree to additional terms before using a specific product or
                  service offered by TopNFlow (“Additional Terms”). To the
                  extent any Additional Terms conflict with these Terms, the
                  Additional Terms govern with respect to your use of the
                  corresponding Service.
                </p>
                <h4>16. Termination</h4>
                <p>
                  You may terminate these Terms at any time and for any reason
                  by deleting your Account and discontinuing use of all
                  Services. If you stop using the Services without deactivating
                  your Account, your Account may be deactivated due to prolonged
                  inactivity.
                </p>
                <p>
                  We may suspend or terminate your Account, moderator status, or
                  ability to access or use the Services at any time for any or
                  no reason, including for violating these Terms.
                </p>
                <p>
                  The following sections will survive any termination of these
                  Terms or of your Account: 4 (Your Content), 6 (Things You
                  Cannot Do), 10 (Indemnity), 11 (Disclaimers), 12 (Limitation
                  of Liability), 13 (Governing Law and Venue), 16 (Termination),
                  and 17 (Miscellaneous).
                </p>
                <h4>17. Miscellaneous</h4>
                <p>
                  These Terms constitute the entire agreement between you and us
                  regarding your access to and use of the Services. Our failure
                  to exercise or enforce any right or provision of these Terms
                  will not operate as a waiver of such right or provision. If
                  any provision of these Terms is, for any reason, held to be
                  illegal, invalid, or unenforceable, the rest of the Terms will
                  remain in effect. You may not assign or transfer any of your
                  rights or obligations under these Terms without our consent.
                  We may freely assign any of our rights and obligations under
                  these Terms.
                </p>
              </b-card-text>
            </b-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Terms",
  data() {
    return {
      
    };
  },
};
</script>
