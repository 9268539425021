export default {
  name: '',
  isUserLoggedIn:localStorage.BOOK_APP_TOKEN?true:false,
  token:localStorage.BOOK_APP_TOKEN?localStorage.BOOK_APP_TOKEN:'',
  username:localStorage.BOOK_APP_USERNAME?localStorage.BOOK_APP_USERNAME:'',
  user_id:localStorage.BOOK_APP_USER_ID?localStorage.BOOK_APP_USER_ID:'',
  topicList:[],
  user_emails:[],
  explanation_title_choices:[],
  topicTree:{},
  activeIndex:undefined,
  parent:undefined,
  sliderSize:4,
  selectedIndex:0,
  selectedIndexObject:{},
  showLoader:false,
  activeItem:{},
  treeUpdater:12345,

  slugTopicId:undefined,
  slug:undefined
}
