<template>
  <div class="topic-page pb-5">
    <Banner parent="tree" />
    <div class="level-up text-right mb-5" v-if="showLevelUp">
      <img
        class="cursor-pointer"
        @click="moveLevelUp()"
        src="@/assets/img/level-up.svg"
        alt="level-up"
        height="50px"
      />
    </div>
    <div class="breadcrumb-container">
      <b-breadcrumb>
        <b-breadcrumb-item
          @click="$router.push('/')"
          active
          href="javascript:void(0)"
          >Home</b-breadcrumb-item
        >
        <b-breadcrumb-item @click="$router.push('/')" href="javascript:void(0)"
          >Tree</b-breadcrumb-item
        >
      </b-breadcrumb>
      <!-- <div>
        <img
          src="@/assets/img/zoom-in.svg"
          @click="zoomTree(-1)"
          height="30"
          class="cursor-pointer mr-1"
          alt="zoom-in"
        />
        <img
          src="@/assets/img/zoom-out.svg"
          @click="zoomTree(1)"
          height="30"
          class="cursor-pointer"
          alt="zoom-out"
        />
      </div> -->
    </div>
    <div class="slider relative" :key="treeUpdater">
      <div
        :class="'slide slide-' + index"
        :key="index"
        v-for="(slide, index) of sliderLength"
      >
        <img
          @click="changeSlide(index, 'left')"
          class="left"
          src="@/assets/img/left.svg"
          alt="left"
        />
        <img
          @click="changeSlide(index, 'right')"
          class="right"
          src="@/assets/img/right.svg"
          alt="right"
        />
      </div>
      <div>
        <!-- <b-overlay :show="showLoader" rounded="sm"> -->
          <figure class="">
            <ul class="tree" :class="'zoom-level-' + sliderSize">
              <node-tree :home="false" root="true" :topic="topicTree" /> 
            </ul>
          </figure>
        <!-- </b-overlay> -->
      </div>
    </div>
  </div>
</template>
<script>
import Banner from "../components/banner/banner.vue";
// import TreeNode from "../components/TreeNode/TreeNode.vue";
import axios from "axios";
import draggable from "vuedraggable";

export default {
  name: "Home",
  components: { Banner, draggable },
  data() {
    return {
      sliderLength: 0,
      topicId: 0,
      childList: {},
      treeDataset: {},
      nodeData: {
        id: 1,
        name: "Root",
        subtopics: [
          {
            id: 1,
            name: "Successful Posibilities",
            subtopics: [
              {
                id: 54,
                name: "Child",
                public: true,
                subtopics: [
                  { id: 1, name: "Child 1-1", public: true },
                  { id: 2, name: "Child 1-2", public: false },
                  { id: 3, name: "Child 1-3", public: true },
                  { id: 4, name: "Child 1-4", public: true },
                  { id: 5, name: "Child 1-5", public: true },
                ],
              },
              { id: 2, name: "Child 2", public: false },
              { id: 3, name: "Child 3", public: true },
              { id: 3, name: "Child 4", public: true },
            ],
          },
          {
            id: 2,
            name: "Successful Posibilities",
          },
          {
            id: 3,
            name: "Successful Posibilities",
            subtopics: [
              // { id: 1, name: "Successful Posibilities", public: true },
              // { id: 2, name: "Successful Posibilities", public: false },
              // { id: 3, name: "Successful Posibilities", public: true },
            ],
          },
          {
            id: 4,
            name: "Successful 1 Posibilities",
            subtopics: [],
          },
          {
            id: 4,
            name: "Successful 2 Posibilities",
            subtopics: [],
          },
          {
            id: 4,
            name: "Successful 3 Posibilities",
            subtopics: [],
          },
        ],
      },
      treeConfig: { nodeWidth: 300, nodeHeight: 150, levelHeight: 200 },
    };
  },
  computed: {
    showLevelUp() {
      return this.topicTree?.parent;
    },
    topicTree() {
      return this.$store.state.topicTree;
    },
    // showLoader() {
    //   return this.$store.state.showLoader;
    // },
    treeUpdater() {
      return this.$store.state.treeUpdater;
    },
    sliderSize() {
      return this.$store.state.sliderSize;
    },
  },
  mounted() {
    this.topicId = this.$route.params.id;
    if (this.topicId) {
      this.getTopicData(this.topicId);
    }
  },
  methods: {
    moveLevelUp() {
      if (this.topicTree.parent) {
        this.getTopicData(this.topicTree.parent);
      }
    },
    async getTopicData(id) {
      let node = await this.getData(id); 
      this.$store.commit("UPDATE_TOPIC_TREE_LIST", node);
    },
    async getData(id) {
      try {
        const config = {
          headers: {
            authorization: `${this.$store.state.token}`,
          },
        };
        let response = await axios.get(
          `${process.env.VUE_APP_API_URL}/topics/${id}/`,
          config
        );
        response.data.subtopics.forEach(async (topic, index) => {
          response.data.subtopics[index] = await this.getData(topic.id);
        });
        this.$store.commit("UPDATE_TREE", 0);
        return response.data;
      } catch (err) {
        return [];
      }
    },
    formatData(node, level) {
      var obj = this.treeDataset;
      var obj1 = {};
      for (let step = 1; step <= level; step++) {
        if (step == 1) {
          obj1 = obj.subtopics;
        } else if (step !== level) {
          obj1 = obj1.subtopics;
        } else {
          obj1.subtopics[0] = node;
        }
        console.log("Global Obj", obj);
      }
      this.treeDataset = obj;
    },
    zoomTree(count) {
      let current = this.$store.state.sliderSize;
      if (current + count <= 4 && current + count > 0) {
        this.$store.commit("UPDATE_SLIDER_SIZE", current + count);
      }
    },
  },
  beforeDestroy() {
    this.$store.commit("UPDATE_TOPIC_TREE_LIST", {});
  },
};
</script>
<style lang="scss" scoped>
.topic-page {
  min-height: calc(100vh - 100px);
  .breadcrumb-container {
    margin-bottom: 50px;
  }
  .node-tree {
    width: 100%;
    height: 700px;
    // border: 1px solid #ccc;
    .node {
      border-radius: 5px;
      &.collapsed-node {
        border: 1px solid $primary;
      }
    }
  }
  .slider {
    position: relative;
    @media all and (max-width: 1024px) {
      max-width: 100%;
      overflow-x: auto;
    }
    // .slide {
    //   position: absolute;
    //   top: 320px;
    //   left: 0;
    //   right: 0;
    //   z-index: 111;
    //   @for $i from 1 through 3 {
    //     &.slide-#{$i} {
    //       top: #{260 * ($i + 1)}px;
    //     }
    //   }
    //   .left,
    //   .right {
    //     position: absolute;
    //     cursor: pointer;
    //   }
    //   .left {
    //     left: -50px;
    //   }
    //   .right {
    //     right: -50px;
    //   }
    // }
  }
}
</style>