<template>
  <div class="topic" @click="changeIndex()">
    <b-card :class="active ? 'selected' : ''" :title="topic.name">
      <b-card-text>
        <img
          class="icon bottomRight"
          @click="$router.push(`topic/${topic.id}`)"
          src="@/assets/img/tree.svg"
      /></b-card-text>
      <img
        class="icon topLeft"
        @click="setAsParent(topic)"
        src="@/assets/img/arrowUp.svg"
      />
    </b-card>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "SubTopicCard",
  props: ["topic", "active","getTopicTreeData"],
  methods: {
    changeIndex() {
      // console.log(this.topic.id);
      this.getTopicTreeData(this.topic.id);
    },
    setAsParent(node) {
      const config = {
        headers: {
          authorization: `${this.$store.state.token}`,
        },
      };
      axios
        .get(`${process.env.VUE_APP_API_URL}/topics/${node.parent}/`, config) // get parent topic
        .then((res) => {
          var index = 0;
          res.data.subtopics.forEach((element, i) => {
            if (element.id == node.id) {
              // check which element matches with the node and save its index
              index = i;
            }
            if (!element.subtopics) {
              element.subtopics = []; // check if subtopics not found then[]
            }
            if (!element.explanations) {
              element.explanations = []; // check if explanation not found then[]
            }
          });
          this.getTopicById(res.data.subtopics, node, index);
          this.getTopicTreeData(this.topic.id);
          this.$store.commit("UPDATE_PARENT", node.parent);
          // this.$store.commit("UPDATE_ACTIVE_INDEX", index);
          // this.$store.commit("UPDATE_ACTIVITY_LIST", res.data.subtopics);
        });
    },
    getTopicById(tree, node, index) {
      // tree is all sibiling topics of node, node is the topic, index is of position in tree
      const config = {
        headers: {
          authorization: `${this.$store.state.token}`,
        },
      };
      axios
        .get(`${process.env.VUE_APP_API_URL}/topics/${node.id}/`, config) // getting the subtopic that clicked
        .then((res) => {
          tree[index] = res.data;
          this.$store.commit("UPDATE_ACTIVE_INDEX", index); // previos work
          this.$store.commit("UPDATE_ACTIVITY_LIST", tree);
        });
    },
   
  },
};
</script>
<style lang="scss" scoped>
.topic {
  position: relative;
  .card {
    margin: auto;
    &.selected {
      background-color: $primary;
      color: #fff;
    }
    height: 150px;
    width: 250px;
    text-align: center;
    .card-body {
      .card-title {
        font-weight: bold;
        text-transform: capitalize;
      }
      display: flex;
      justify-content: center;
      align-items: center;
      .icon {
        position: absolute;
        cursor: pointer;
        &.topRight {
          top: 10px;
          right: 10px;
        }
        &.topLeft {
          top: 10px;
          left: 10px;
        }
        &.bottomRight {
          bottom: 10px;
          right: 10px;
        }
        &.bottomLeft {
          bottom: 10px;
          left: 10px;
        }
      }
    }
  }
}
</style>