<template>
  <div id="app">
    <Header />
    <div class="container">
      <router-view></router-view>
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from "./components/header/header.vue";
import Footer from "./components/footer/footer.vue";
export default {
  name: "Layout",
  components: {
    Header,
    Footer,
  },
};
</script>
<style lang="scss">
.breadcrumb-item {
  cursor: pointer;
}

// tree styling
.tree,
.tree ul,
.tree li {
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
}

.tree {
  margin: 50px auto 100px;
  text-align: center;
}
.zoom-level-1 {
  transform: scale(1.3);
}
.zoom-level-2 {
  transform: scale(1.2);
}
.zoom-level-3 {
  transform: scale(1.1);
}
.zoom-level-4 {
  transform: scale(1);
}
.tree,
.tree ul {
  display: table;
}
.tree ul {
  width: 100%;
}
.tree li {
  display: table-cell;
  padding: 0.5em 0;
  vertical-align: top;
}
/* _________ */
.tree li:before {
  outline: solid 1px $primary;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.tree li:first-child:before {
  left: 50%;
}
.tree .node {
  z-index: 999;
}
.tree li.root > div > .node:before {
  display: none;
}
.tree li.root > div > .node {
  background-color: $primary;
  color: #fff;
}
.tree li:last-child:before {
  right: 50%;
}
.node .card {
  background-color: transparent !important;
  border: none !important;
}

.tree .node,
.tree span {
  // border: solid 0.1em $primary;
  border-radius: 0.2em;
  display: inline-block;
  margin: 0 0.2em 0.5em;
  padding: 0.2em 0.5em;
  position: relative;
  background-color: #fff;
}
/* If the tree represents DOM structure */
.tree .node {
  font-family: monaco, Consolas, "Lucida Console", monospace;
}

/* | */
.tree ul:before,
.tree .node:before,
.tree span:before {
  outline: solid 1px $primary;
  content: "";
  height: 0.5em;
  left: 50%;
  position: absolute;
}
.tree ul:before {
  top: -0.5em;
}
.tree .node:before,
.tree span:before {
  top: -0.55em;
}

/* The root node doesn't connect upwards */
.tree > li {
  margin-top: 0;
}
.tree > li:before,
.tree > li:after,
.tree > li > .node:before,
.tree > li > span:before {
  outline: none;
}
.tree li.last:before {
  right: 50%;
}
// tree style

// slider style
// .tree .slick-initialized .slick-slide {
//   width: 275px !important;
// }
// .tree .slick-track {
//   margin: auto !important;
// }
// slider style
.dropdown-toggle-no-caret:after {
  display: none !important;
}
.toast {
  position: fixed;
  top: 10px;
  right: 10px;
  opacity: 1 !important;
}
.parent-li:not(.root) {
  max-width: 270px;
  &:after {
    outline: solid 1px #1a6efc;
    content: "";
    height: 0.5em;
    left: 136px;
    position: absolute;
    bottom: 0;
    top: 164px;
  }
}
ul.has-children:before {
  // left: 136px;
  display: none;
}
</style>
