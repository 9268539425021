<template>
  <li
    class="item"
    :class="`${last ? 'last' : ''} ${
      hasCHildren(topic) && activeItem ? 'parent-li' : ''
    } ${root ? 'root' : ''}`"
  >
  <!-- changes for subtopic tree on home page  if home==true rendering from subtopic-->
  
<TreeNode v-if="!home"  :siblings='siblings' :topicIndex='topicIndex' :topic="topic" />
  
  
  <!-- changes for subtopic tree on home page  -->
    <!-- <TreeNode :siblings='siblings' :topicIndex='topicIndex' :topic="topic" /> -->

    <template v-if="topic.subtopics && (activeItem || root)">
      
      <ul v-if="topic.subtopics.length" :class="!root ? 'has-children' : ''">
        <template v-for="(l1, i1) in slides">
          <!-- <h1 :key="i1+'1'">p:{{topic.id}} s:{{selectedIndex}}</h1> -->
          
          <nodeTree
            :activeItem="i1 == selectedIndex"
            :last="i1 == sliderSize - 1 || i1 == topic.subtopics.length - 1"
            :key="i1"
            :topic="l1"
            :siblings='topic.subtopics'
            :topicIndex='i1'
          />
          <!-- :changeNode='changeNode(i1)' -->
        </template>
        <div class="custom-arrow left">
          <img @click="previous()" src="@/assets/img/left.svg" alt="left" />
        </div>
        <div class="custom-arrow right">
          <img @click="next()" src="@/assets/img/right.svg" alt="right" />
        </div>
        <!-- </template> -->
        <!-- </VueSlickCarousel> -->
        <!-- <node-tree
            v-for="(child, index) in topic.subtopics"
            :topic="child"
            :key="index"
          /> -->
      </ul>
    </template>
    <b-modal hide-footer ref="confirmationMessage" title="Update Confirmation">
      <p class="my-4">Are you Sure you want to update?</p>
      <b-button variant="primary" @click="updateParentNode()">Update</b-button
      >&nbsp;&nbsp;
      <b-button @click="closeModal()">Cancel</b-button>
    </b-modal>
  </li>
</template>
<script>
import TreeNode from "../TreeNode/TreeNode.vue";
import draggable from "vuedraggable";

export default {
  name: "nodeTree",
  inheritAttrs: false,
  props: ["topic", "root", "last", "activeItem", "changeNode","siblings","topicIndex","home"],
  components: { TreeNode, draggable },
  beforeCreate: function () {
    // this.$options.components.nodeTree =require("./Tree.vue").default;
  },
  data() {
    return {
      activeIndex: 0,
      updatedNode: {},
      collapsed: false, //change it to true to enable functionality
    };
  },
  watch: {
    topic(value) {
      // console.log("Topic New Value", value);
    },
  },
  computed: {
    sliderSize() {
      if (this.topic?.subtopics) {
        if (
          this.topic.subtopics.length <= 4 &&
          this.topic.subtopics.length != 1
        ) {
          // return this.topic.subtopics.length -1; // old code
          return this.topic.subtopics.length ;
        }
      }
      return this.$store.state.sliderSize;
    },
    slides() {
      return this.topic.subtopics.slice(
        this.activeIndex,
        this.sliderSize + this.activeIndex
      );
    },
    selectedIndex(){
      return this.$store.state.selectedIndexObject[this.topic.id] || 0;
    }
  },
  
  methods: {
    onAddEvent(e) {
      console.log(e);
    },
    
    hasCHildren(node) {
      return node?.subtopics?.length;
    },
    changeCollapsed(value) {
      this.collapsed = value;
    },
    previous() {
      if (this.activeIndex > 0) {
        this.activeIndex--;
      }
    },
    next() {
      if (this.topic.subtopics.length > this.sliderSize + this.activeIndex) {
        this.activeIndex++;
      }
    },
    checkMove: function (evt) {
      if (evt?.dragged?.id && evt?.relatedContext?.element?.id) {
        this.updateNode(evt.dragged.id, evt?.relatedContext?.element);
      }
      return false;
    },
    updateNode(child, parent) {
      this.updateNode = {
        child,
        parent,
      };
      this.$refs["confirmationMessage"].show();
    },
    updateParentNode() {
      this.updateNode;
    },
    closeModal() {
      this.$refs["confirmationMessage"].hide();
    },
  },
};
</script>
<style>
.custom-arrow {
  position: absolute;
  top: 50px;
  /* bottom: 0;
  margin: auto; */
  display: flex;
  align-items: center;
  cursor: pointer;
  z-index: 999;
}
.custom-arrow.left {
  left: -45px;
}
.custom-arrow.right {
  right: -45px;
}
</style>