export const ADD_NAME = `ADD_NAME`
export const UPDATE_TOKEN = `UPDATE_TOKEN`
export const UPDATE_USERNAME = `UPDATE_USERNAME`
export const UPDATE_USER_ID = `UPDATE_USER_ID`
export const UPDATE_LOGIN_STATUS = `UPDATE_LOGIN_STATUS`
export const UPDATE_ACTIVE_INDEX = `UPDATE_ACTIVE_INDEX`
export const UPDATE_PARENT = `UPDATE_PARENT`
export const UPDATE_ACTIVE_ITEM = `UPDATE_ACTIVE_ITEM`
export const UPDATE_ACTIVITY_LIST = `UPDATE_ACTIVITY_LIST`
export const UPDATE_TOPIC_TREE_LIST = `UPDATE_TOPIC_TREE_LIST`
export const UPDATE_SLIDER_SIZE = `UPDATE_SLIDER_SIZE`
export const UPDATE_SELECTED_INDEX = `UPDATE_SELECTED_INDEX`
export const UPDATE_SELECTED_INDEX_OBJECT = `UPDATE_SELECTED_INDEX_OBJECT`
export const UPDATE_TREE = `UPDATE_TREE`
export const UPDATE_LOADER = `UPDATE_LOADER`
export const UPDATE_USER_EMAILS = `UPDATE_USER_EMAILS`
export const UPDATE_EXPLANATION_TITLE_OPTIONS = `UPDATE_EXPLANATION_TITLE_OPTIONS`

export const UPDATE_SLUG_ID = `UPDATE_SLUG_ID`
export const UPDATE_SLUG = `UPDATE_SLUG`

