<template>
  <div class="topic">
    <b-card
      @click="changeIndex()"
      :class="active ? 'selected' : ''"
      :title="topic.name"
    >
      <b-card-text> 
        <!-- For Public Private -->
        <b-dropdown
          v-if="isLoggedIn"
          class="icon topRight"
          size="sm"
          variant="link"
          toggle-class="text-decoration-none"
          no-caret
        >
          <template #button-content>
            <img v-if="topic.private" src="@/assets/img/private.svg" />
            <img v-else src="@/assets/img/public.svg" />
          </template>
          <b-dropdown-item @click="updateNode({ ...topic, private: false })"
            >Public</b-dropdown-item
          >
          <b-dropdown-item @click="updateNode({ ...topic, private: true })"
            >Private</b-dropdown-item
          >
        </b-dropdown>
        <img
          class="icon topRight"
          v-else-if="topic.private && !isLoggedIn"
          src="@/assets/img/private.svg"
        />
        <img
          class="icon topRight"
          v-else-if="!isLoggedIn"
          src="@/assets/img/public.svg"
        />
        <img
          v-if="isLoggedIn && isAuthenticated"
          @click.prevent="shareTopic(topic)"
          src="@/assets/img/share.svg"
          class="icon bottomRightfour cursor-pointer"
          alt=""
        />
        
        <img
          v-if="isLoggedIn && isAuthenticated"
          @click.prevent="editTopic(topic)"
          src="@/assets/img/pen2.svg"
          class="icon bottomRighttwo cursor-pointer"
          alt=""
        />
        <img
          v-if="isLoggedIn && isAuthenticated"
          @click.prevent="delTopic(topic)"
          src="@/assets/img/delete2.svg"
          class="icon bottomRight cursor-pointer"
          alt=""
        />
        <img
          @click="$router.push(`topic/${topic.id}`)"
          class="icon bottomRightthree cursor-pointer"
          src="@/assets/img/tree.svg"
        />
      </b-card-text>
    </b-card>

    <!-- share topic modal -->
    <b-modal id="modal-1" ref="shareTopicModal" hide-footer title="Share Topic">
      <div>
        <div class="error-message" v-if="Object.keys(errorList).length">
          <b-alert show variant="danger">
            <template v-for="(error, index) in Object.keys(errorList)">
              <div :key="index">
                <strong>{{ error }}:</strong>
                <p>
                  {{ errorList[error] }}
                </p>
              </div>
            </template>
          </b-alert>
        </div>
        <div class="error-message" v-if="Object.keys(successList).length">
          <b-alert show variant="danger">
            <template v-for="(error, index) in Object.keys(successList)">
              <div :key="index">
                <strong>{{ message }}:</strong>
                <p>
                  {{ successList[error] }}
                </p>
              </div>
            </template>
          </b-alert>
        </div>
        <div>
          <b-form-group id="fieldset-1" label="Title">
            <b-form-input
              disabled
              id="input-1"
              v-model="topic.name"
              trim
            ></b-form-input>
          </b-form-group>
        </div>
        <div>
          <b-form-group id="fieldset-1" label="User">
            <b-form-input
              id="input-1"
              v-model="email"
              autocomplete="off"
              trim
              @input="filterEmails"
              @focus="autocompleteModel = true" 
            ></b-form-input>
            <div v-if="filteredEmails && autocompleteModel">
              <ul class="emailselect list-unstyled text-center z-1">
                <li
                  class="cursor-pointer email-bg"
                  v-for="(filteredEmail, index) in filteredEmails"
                  :key="index"
                  @click="setEmail(filteredEmail)"
                >
                  {{ filteredEmail.email }}
                </li>
              </ul>
            </div>
          </b-form-group>
        </div>
        <b-form-group
          id="input-group-3"
          label="Access Level"
          label-for="input-3"
        >
          <b-form-select
            id="input-3"
            v-model="access_level"
            :options="access_level_options"
            required
          ></b-form-select>
        </b-form-group>

        <b-button
          class="mt-3"
          block
          variant="primary"
          @click="shareTopicConfirm()"
          >Share</b-button
        >
      </div>
    </b-modal>

    <!-- edit topic modal -->
    <b-modal id="modal-1" ref="editTopicModal" hide-footer title="Update Topic">
      <div>
        <!-- <div class="breadcrumb-container mb-2 grey-bg">
          <b-breadcrumb>
            <b-breadcrumb-item
              :class="i < breadcrumbListForModal.length - 1 ? 'active' : ''"
              :key="i"
              v-for="(breadcrumb, i) in breadcrumbListForModal"
              href="javascript:void(0)"
              >{{ breadcrumb }}</b-breadcrumb-item
            >
          </b-breadcrumb>
        </div> -->

        <div>
          <b-form-group id="fieldset-1" label="Enter Title">
            <b-form-input id="input-1" v-model="topic.name" trim></b-form-input>
          </b-form-group>
        </div>
        <!-- <div>
          <b-form-group id="fieldset-1" label="Type">
            <b-form-select v-model="topic.type">
              <template #first>
                <b-form-select-option :value="null" disabled
                  >-- Topic Type --</b-form-select-option
                >
              </template>
              <b-form-select-option
                :key="index"
                v-for="(type, index) in typeList"
                :value="type.value"
                >{{ type.label }}</b-form-select-option
              >
            </b-form-select>
          </b-form-group>
        </div> -->
        <!-- <div>
          <b-form-group id="fieldset-1" label="Parent">
            <b-form-select v-model="topic.parent">
              <template #first>
                <b-form-select-option :value="null" disabled
                  >-- Parent --</b-form-select-option
                >
              </template>
              <b-form-select-option
                :key="index"
                v-for="(type, index) in allTopicList"
                :value="type.id"
                >{{ type.name }}</b-form-select-option
              >
            </b-form-select>
          </b-form-group>
        </div> -->
        <b-form-checkbox
          id="checkbox-1"
          v-model="topic.private"
          name="checkbox-1"
        >
          Private
        </b-form-checkbox>
        <b-button
          class="mt-3"
          block
          variant="primary"
          @click="updateTopic(topic)"
          >Update</b-button
        >
      </div>
    </b-modal>
    <!-- Delete topic modal -->
    <b-modal hide-footer ref="deleteTopicModal" title="Delete Confirmation">
      <p class="my-4">
        All child nodes will be deleted. Are you Sure you want to Delete the
        topic?
      </p>
      <b-button variant="primary" @click="deleteTopic(topic)">Delete</b-button
      >&nbsp;&nbsp;
      <b-button @click="closeModal()">Cancel</b-button>
    </b-modal>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "TopicCard",
  props: ["topic", "active", "index", "typeList","getTopicTreeData"],
  data() {
    return {
      errorList: {},
      successList: {},

      // mutableActive: this.active,
      access_level: false,
      access_level_options: [
        { value: true, text: "Edit" },
        { value: false, text: "View" },
      ],
      user: null,
      email: "",
      filteredEmails: [],
      autocompleteModel: false,
      slug:'',
    };
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.isUserLoggedIn;
    },
    isAuthenticated() {
      return this.topic.authenticated;
    },
    currentTopicIndex() {
      return this.$store.state.activeIndex;
    },
    allUserEmails() {
      return this.$store.state.user_emails;
    },
  },
  mounted() {
    if (!this.allUserEmails.length) {
      this.getUserEmails();
    }
    // console.log(this.allUserEmails);
  },
  methods: {
    getUserEmails() {
      const config = {
        headers: {
          authorization: `${this.$store.state.token}`,
        },
      };
      axios
        .get(`${process.env.VUE_APP_API_URL}/user_email/`, config)
        .then((res) => {
          this.$store.commit("UPDATE_USER_EMAILS", res.data);
        });
    },
    setEmail(email) {
      this.user = email;
      this.email = email.email;
      this.autocompleteModel = false;
      // console.log(this.user);
    },
    filterEmails() {
      if (this.email == "" || this.email == null) {
        this.filteredEmails = [];
      } else {
        this.filteredEmails = this.allUserEmails.filter((data, i) => {
          return data.email.toLowerCase().startsWith(this.email.toLowerCase());
        });
      }
      // return email.toLowerCase().startsWith(this.email.toLowerCase());
    },
    changeIndex() {
      // console.log("changeIndex");
      // console.log(this.$store.state.activeIndex);
      // console.log(this.$store.state.topicList);
      // console.log(this.index);
      // This make topic active and unactive (toggle)
      this.$store.commit(
        "UPDATE_ACTIVE_INDEX",
        this.currentTopicIndex == this.index ? undefined : this.index // previous logic
      );

      // For subTopicTree on Home page

      // console.log(this.topic.id)
      this.getTopicTreeData(this.topic.id);

      // console.log("active");
      // console.log(this.active);
      // this.active = this.active? false: true // new added but not working
      // console.log("changeIndex2");
      // console.log(this.$store.state.activeIndex);
      // console.log(this.$store.state.topicList);
    },

    updateNode(node) {
      const config = {
        headers: {
          authorization: `${this.$store.state.token}`,
        },
      };
      axios
        .put(`${process.env.VUE_APP_API_URL}/topics/${node.id}/`, node, config)
        .then(() => {
          this.getTopicList();
        })
        .catch((e) => {
          let keys = Object.keys(e.response.data);
          this.$bvToast.toast(`${e.response.data[keys[0]]}`, {
            title: "Warning",
            autoHideDelay: 10000,
            visible: true,
            variant: "warning",
            appendToast: true,
          });
        });
    },
    getTopicList() {
      const config = {
        headers: {
          authorization: `${this.$store.state.token}`,
        },
      };
      axios
        .get(`${process.env.VUE_APP_API_URL}/topics/`, config)
        .then((res) => {
          // console.log('UPDATE_ACTIVITY_LIST is changing here')
          this.$store.commit("UPDATE_ACTIVITY_LIST", res.data);
        });
    },
    // For share modal of topic
    shareTopic(item) {
      this.topic = item;
      // alert(JSON.stringify(item))
      this.$refs["shareTopicModal"].show();
    },
    // For updating the topic
    shareTopicConfirm() {
      let form = new FormData();
      form.append("topic", this.topic.id);
      form.append("user", this.user.id);
      form.append("access_level", this.access_level);

      const config = {
        headers: {
          authorization: `${this.$store.state.token}`,
        },
      };
      // saving topic sharing
      axios
        .post(`${process.env.VUE_APP_API_URL}/share_topic/`, form, config)
        .then((res) => {
          // console.log(res.data);
          // console.log(res.data.slug);
          
          // print(`${process.env.VUE_APP_API_URL}/share_topic/${res.data.slug}/`);
          // sending email api call
          // this.slug = res.data.slug
          let keys = Object.keys(res.data.slug);
          this.$bvToast.toast(`Topic Shared successfully`, {
            title: "Success",
            autoHideDelay: 10000,
            visible: true,
            variant: "success",
            appendToast: true,
          });
          this.$refs["shareTopicModal"].hide();
        })
        .catch((e) => {
          let keys = Object.keys(e.response.data);
          this.$bvToast.toast(`${e.response.data[keys[0]]}`, {
            title: "Warning",
            autoHideDelay: 10000,
            visible: true,
            variant: "warning",
            appendToast: true,
          });
          this.$refs["shareTopicModal"].hide();
          // console.log(e);
        });
      // sending email
      // let form_email = new FormData();
      //     form_email.append("email", this.user.email);
      //     form_email.append(
      //       "url",
      //       `${process.env.VUE_APP_API_URL}/${this.slug}/`
      //     );
      // axios
      //   .post(
      //     `${process.env.VUE_APP_API_URL}/send_email_topic_share/`,
      //     form_email,
      //     config
      //   )
      //   .then((res) => {
      //     // console.log("email");
      //     // console.log(res);
      //     let keys = Object.keys(res.data.url);
      //     this.$bvToast.toast(
      //       `Email Sent successfully, sharing link for this topic is \n ${res.data.url}/`,
      //       {
      //         title: "Success",
      //         autoHideDelay: 10000,
      //         visible: true,
      //         variant: "success",
      //         appendToast: true,
      //       }
      //     );
      //     this.$refs["shareTopicModal"].hide();
      //   })
      //   .catch((e) => {
      //     let keys = Object.keys(e.response.data);
      //     this.$bvToast.toast(`${e.response.data[keys[0]]}`, {
      //       title: "Warning",
      //       autoHideDelay: 10000,
      //       visible: true,
      //       variant: "warning",
      //       appendToast: true,
      //     });
      //     console.log(e);
      //   });
      //////////////////
    },
    // For edit modal of topic
    editTopic(item) {
      this.topic = item;
      // alert(JSON.stringify(item))
      this.$refs["editTopicModal"].show();
    },
    // For updating the topic
    updateTopic(topic) {
      // console.log('update')
      // console.log(topic);
      const config = {
        headers: {
          authorization: `${this.$store.state.token}`,
        },
      };
      // if (this.isSubTopic) {
      //   this.topic.parent = this.getParentMain?.id
      //     ? this.getParentMain?.id
      //     : null;
      // } else {
      //   this.topic.parent = this.getParentMain?.parent
      //     ? this.getParentMain.parent
      //     : null;
      // }
      axios
        .put(
          `${process.env.VUE_APP_API_URL}/topics/${topic.id}/`,
          topic,
          config
        )
        .then((res) => {
          // this.getTopicList();    // commented because of error (topic screen reset to Level1)
          // alert('updated')
          // this.topic = {
          //   name: "",
          //   type: null,
          //   parent: null,
          //   private: false,
          // };
          this.$refs["editTopicModal"].hide();
          // this.getTopicList(true, res.data.id);
        })
        .catch((e) => {
          let keys = Object.keys(e.response.data);
          this.$bvToast.toast(`${e.response.data[keys[0]]}`, {
            title: "Warning",
            autoHideDelay: 10000,
            visible: true,
            variant: "warning",
            appendToast: true,
          });
        });
    },
    // For delete modal of topic
    closeModal() {
      this.$refs["deleteTopicModal"].hide();
    },
    delTopic(item) {
      this.topic = item;
      // alert(JSON.stringify(item))
      this.$refs["deleteTopicModal"].show();
    },
    deleteTopic(topic) {
      // console.log('update')
      // console.log(topic);
      const config = {
        headers: {
          authorization: `${this.$store.state.token}`,
        },
      };
      axios
        .delete(`${process.env.VUE_APP_API_URL}/topics/${topic.id}/`, config)
        .then((res) => {
          // console.log(res);
          // this.getTopicList();     // commented because of error (topic screen reset to Level1)
          this.$refs["deleteTopicModal"].hide();
        })
        .catch((e) => {
          // console.log(e);
          let keys = Object.keys(e.response.data);
          this.$bvToast.toast(`${e.response.data[keys[0]]}`, {
            title: "Warning",
            autoHideDelay: 10000,
            visible: true,
            variant: "warning",
            appendToast: true,
          });
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.emailselect {
  overflow-y: scroll;
  position: absolute;
  width: 93%;
  max-height: 200px;
}
.email-bg {
  padding: 7px;
  background-color: #ecf1f5;
  border: #d1d7db 0.5px solid;
}
.topic {
  position: relative;
  .card {
    margin: auto;
    &.selected {
      background-color: $primary;
      color: #fff;
    }
    height: 150px;
    width: 250px;
    text-align: center;
    .card-body {
      .card-title {
        font-weight: bold;
        text-transform: capitalize;
      }
      display: flex;
      justify-content: center;
      align-items: center;
      .icon {
        position: absolute;
        &.topRight {
          top: 10px;
          right: 10px;
        }
        &.topRighttwo {
          top: 18px;
          right: 50px;
        }
        &.topLeft {
          top: 10px;
          left: 10px;
        }
        // &.topLeft {
        //   top: 15px;
        //   left: 150px;
        // }
        &.bottomRight {
          bottom: 10px;
          right: 60px;
        }
        &.bottomRighttwo {
          bottom: 10px;
          right: 35px;
        }
        &.bottomRightthree {
          size: 2px;
          bottom: 10px;
          right: 10px;
        }
        &.bottomRightfour {
          // size: 2px;
          bottom: 10px;
          right: 85px;
        }
        &.bottomLeft {
          bottom: 10px;
          left: 10px;
        }
      }
    }
  }
}
</style>