<template>
  <div class="home" :class="isMobile ? 'mobile' : ''">
    <br>
    <h1>About Us</h1>
    <div class="content pb-5">
      <!-- <div class="row" key="updateHorizontalSlider"></div> -->
      <div class="pt-2 mb-5">
        <div class="row">
          <div class="sub-topic-content mt-5">
            <h4 class="title">About Us</h4>
            <b-card>
              <b-card-text
                >Lorem Ipsum is simply dummy text of the printing and
                typesetting industry. From lorem Ipsum has been the industry's
                standard dummy text ever since the 1500s, when an unknown
                printer took a galley of type and scrambled it to
                make.</b-card-text
              >
              <b-card-text
                >Lorem Ipsum is simply dummy text of the printing and
                typesetting industry. From lorem Ipsum has been the industry's
                standard dummy text ever since the 1500s, when an unknown
                printer took a galley of type and scrambled it to
                make.</b-card-text
              >
              <b-card-text
                >Lorem Ipsum is simply dummy text of the printing and
                typesetting industry. From lorem Ipsum has been the industry's
                standard dummy text ever since the 1500s, when an unknown
                printer took a galley of type and scrambled it to
                make.</b-card-text
              >
              <b-card-text
                >Lorem Ipsum is simply dummy text of the printing and
                typesetting industry. From lorem Ipsum has been the industry's
                standard dummy text ever since the 1500s, when an unknown
                printer took a galley of type and scrambled it to
                make.</b-card-text
              >
              <b-card-text
                >Lorem Ipsum is simply dummy text of the printing and
                typesetting industry. From lorem Ipsum has been the industry's
                standard dummy text ever since the 1500s, when an unknown
                printer took a galley of type and scrambled it to
                make.</b-card-text
              >
            </b-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "About",
  data() {
    return {
      
    };
  },
};
</script>
