import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueTree from '@ssthouse/vue-tree-chart'
import nodeTree from './components/Tree/Tree.vue'
import { ToastPlugin } from 'bootstrap-vue'

Vue.component('vue-tree', VueTree)
Vue.component('node-tree', nodeTree)

import { VueEditor } from "vue2-editor";

Vue.use( VueEditor );
// Vue.use( VueRecaptcha );


// import MyPlugin from './plugins/my-plugin'

Vue.use(BootstrapVue)
Vue.use(ToastPlugin)

Vue.config.productionTip = false
Vue.prototype.$log = console.log

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
