<template>
  <div class="home" :class="isMobile ? 'mobile' : ''">
    <!-- Banner is what where search input and button is displayed -->
    <Banner parent="home" />
    <!-- show level up is the icon when clicking any subnode and wants to show parent node -->
    <div class="level-up text-right mb-5" v-if="showLevelUp">
      <img
        class="cursor-pointer"
        @click="moveLevelUp()"
        src="@/assets/img/level-up.svg"
        alt="level-up"
        height="50px"
      />
    </div>
    <!-- Page content begins -->
    <div class="content pb-5">
      <!-- First row -->
      <div class="row" key="updateHorizontalSlider">
        <!-- Slider of all parent nodes -->
        <div
          class="col-md-9 col-xs-12"
          :key="'main-slider-' + allTopicList.length"
        >
          <VueSlickCarousel
            ref="mainSlider"
            v-if="allTopicList.length"
            class="carousel"
            v-bind="settings"
            :arrows="true"
            :dots="false"
          >
            <TopicCard
              v-for="(topic, index) of allTopicList"
              :active="index == currentTopicIndex"
              :index="index"
              :topic="topic"
              :key="topic.id"
              :typeList="typeList"
              :getTopicTreeData="getTopicTreeData"
            />
            <template #prevArrow>
              <div class="custom-arrow left">
                <img src="@/assets/img/left.svg" alt="" />
              </div>
            </template>
            <template #nextArrow>
              <div class="custom-arrow right">
                <img src="@/assets/img/right.svg" alt="" />
              </div>
            </template>
          </VueSlickCarousel>
        </div>

        <!-- Add Topic Button -->
        <div class="col-md-3 col-xs-12">
          <div :class="!isMobile ? 'float-right' : 'pt-3'">
            <b-card
              v-if="isLoggedIn"
              @click="openAddTopic(0)"
              :class="isMobile ? 'mx-auto' : ''"
              class="cursor-pointer addTopic parent-add"
              title="Add Topic"
            >
            </b-card>
          </div>
        </div>
        <!-- Add Topic Button End -->
      </div>
      <!-- Second row -->
      <div class="pt-5 mt-3">
        <div class="row">
          <!-- for sub topic  -->
          <div
            class="col-lg-3 col-xs-4"
            :class="isMobile ? 'mb-5 pb-5' : 'subtopicSlider-container'"
          >
            <VueSlickCarousel
              v-if="allTopicList[currentTopicIndex]"
              class="carousel"
              ref="subtopicSlider"
              v-bind="columnSettings"
              :arrows="true"
              :dots="false"
            >
              <template v-for="(sr, index) in getSubTopicSliderLength">
                <div
                  :class="
                    !allTopicList[currentTopicIndex].subtopics[index] &&
                    !isLoggedIn
                      ? 'd-none'
                      : ''
                  "
                  @click="activeIndex = index"
                  :key="index"
                >
                  <SubTopicCard
                    v-if="allTopicList[currentTopicIndex].subtopics[index]"
                    :active="index == activeIndex"
                    @click="activeIndex = index"
                    :topic="allTopicList[currentTopicIndex].subtopics[index]"
                    :getTopicTreeData="getTopicTreeData"
                  />
                  <div v-else-if="isLoggedIn" class="">
                    <!-- v-if="isLoggedIn" -->
                    <b-card
                      :class="isMobile ? 'mx-auto' : ''"
                      @click="openAddTopic(1)"
                      class="addTopic addSubTopic mb-2"
                      title="Add"
                    >
                    </b-card>
                  </div>
                </div>
              </template>
              <template #prevArrow>
                <div class="custom-arrow bottom">
                  <img src="@/assets/img/left.svg" alt="" />
                </div>
              </template>
              <template #nextArrow>
                <div class="custom-arrow top">
                  <img src="@/assets/img/right.svg" alt="" />
                </div>
              </template>
            </VueSlickCarousel>
          </div>
          <!-- subtopic slider ends -->

          <div class="col-lg-9 col-xs-8">
            <!-- breadcrumb start -->
            <div class="breadcrumb-container">
              <b-breadcrumb>
                <b-breadcrumb-item
                  :class="i < breadcrumbList.length - 1 ? 'active' : ''"
                  :key="i"
                  v-for="(breadcrumb, i) in breadcrumbList"
                  href="javascript:void(0)"
                  @click="goTo(i)"
                  >{{ breadcrumb }}
                </b-breadcrumb-item>
              </b-breadcrumb>
              <!-- add + button for explanation modal-2 is for explanation-->
              <div>
                <img
                  class="cursor-pointer mr-3 mb-3 bg-white"
                  @click="downloadPDF"
                  v-if="isLoggedIn"
                  src="@/assets/img/documents2.svg"
                  alt=""
                />
                <img
                  class="cursor-pointer mb-3 bg-white"
                  @click="showSubTopicTree = !showSubTopicTree"
                  v-if="isLoggedIn"
                  src="@/assets/img/tree2.svg"
                  alt=""
                />
                <img
                  v-if="isLoggedIn"
                  v-b-modal.modal-2
                  src="@/assets/img/plus.svg"
                  alt=""
                />
              </div>
            </div>

            <div
              class="sub-topic-content"
              v-if="allTopicList[currentTopicIndex]"
            >
              <!-- SubTopicTree go here -->

              <figure v-if="showSubTopicTree" :key="treeUpdater" class="">
                <ul ref="homeTree" class="tree">
                  <node-tree :home="true" root="true" :topic="getSubTopics" />
                </ul>
              </figure>

              <!-- explainations go here -->

              <template v-for="(item, index) in getExplainations">
                <b-card class="mb-5" :title="item.title" :key="index">
                  <b-card-text v-html="item.type" class="text">
                    <!-- item.type -->
                  </b-card-text>
                  <!-- <b-card-text class="text">
                    {{item.type}}
                  </b-card-text> -->
                  <b-card-text>
                    <div
                      class="d-flex justify-content-between align-items-center"
                    >
                      <div class="tags">
                        <!-- <b-button
                          disabled="disabled"
                          class="mr-2"
                          variant="primary"
                          >Topic</b-button
                        >
                        <b-button disabled="disabled" variant="primary"
                          >Sub Topic</b-button
                        > -->
                      </div>
                      <div>
                        <img class="mr-2" src="@/assets/img/check.svg" alt="" />
                        <img
                          v-if="isLoggedIn"
                          @click="editExplaination(item)"
                          src="@/assets/img/pen.svg"
                          alt=""
                        />
                        <img
                          v-if="isLoggedIn"
                          @click="delExplanation(item)"
                          class="ml-2"
                          src="@/assets/img/delete.svg"
                          alt=""
                        />
                      </div>
                    </div>
                  </b-card-text>
                </b-card>
              </template>
              <!-- explanation card ends -->

              <b-breadcrumb style="width: 120px">
                <b-breadcrumb-item>Contributors </b-breadcrumb-item>
              </b-breadcrumb>
              <div class="files-card">
                <div
                  class="file-item"
                  :key="index"
                  v-for="(item, index) in getContributors"
                >
                  <b-card
                    class="mb-5"
                    :title="item.history_user_name"
                    :key="index"
                  ></b-card>
                  <!-- <b-card title="item.type"></b-card> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- cards end here -->
    <!-- add topic modal -->
    <b-modal
      id="modal-1"
      ref="createTopicModal"
      hide-footer
      title="Create Topic"
    >
      <div>
        <div class="breadcrumb-container mb-2 grey-bg">
          <b-breadcrumb>
            <b-breadcrumb-item
              :class="i < breadcrumbListForModal.length - 1 ? 'active' : ''"
              :key="i"
              v-for="(breadcrumb, i) in breadcrumbListForModal"
              href="javascript:void(0)"
              >{{ breadcrumb }}</b-breadcrumb-item
            >
          </b-breadcrumb>
        </div>

        <div>
          <b-form-group id="fieldset-1" label="Enter Title">
            <b-form-input id="input-1" v-model="topic.name" trim></b-form-input>
          </b-form-group>
        </div>
        <div v-if="!isSubTopic">
          <b-form-group id="fieldset-1" label="Type">
            <b-form-select v-model="topic.type">
              <template #first>
                <b-form-select-option :value="null" disabled
                  >-- Topic Type --</b-form-select-option
                >
              </template>
              <b-form-select-option
                :key="index"
                v-for="(type, index) in typeList"
                :value="type.value"
                >{{ type.label }}</b-form-select-option
              >
            </b-form-select>
          </b-form-group>
        </div>
        <div v-if="!isSubTopic">
          <b-form-checkbox
            id="checkbox-1"
            v-model="topic.private"
            name="checkbox-1"
          >
            Private
          </b-form-checkbox>
        </div>
        <b-button class="mt-3" block variant="primary" @click="saveTopic()"
          >Save</b-button
        >
      </div>
    </b-modal>

    <!-- explanation add modal -->
    <b-modal
      id="modal-2"
      hide-footer
      ref="createExplanationsModal"
      title="Explanation"
    >
      <div>
        <div class="breadcrumb-container mb-2 grey-bg">
          <b-breadcrumb>
            <b-breadcrumb-item
              :class="i < breadcrumbListForModal.length - 1 ? 'active' : ''"
              :key="i"
              v-for="(breadcrumb, i) in breadcrumbListForModal"
              href="javascript:void(0)"
              >{{ breadcrumb }}</b-breadcrumb-item
            >
          </b-breadcrumb>
        </div>
        <div>
          <b-form-group id="fieldset-1" label="Enter Title">
            <b-form-input
              id="input-1"
              v-model="explanations.title"
              autocomplete="off"
              trim
              @input="filterChoices"
              @focus="autocompleteModel = true"
            ></b-form-input>
            <div v-if="filteredChoices && autocompleteModel">
              <ul class="emailselect list-unstyled text-center">
                <li
                  class="cursor-pointer email-bg"
                  v-for="(filteredChoice, index) in filteredChoices"
                  :key="index"
                  @click="setExplanationTitle(filteredChoice)"
                >
                  {{ filteredChoice.choice }}
                </li>
              </ul>
            </div>
          </b-form-group>
        </div>
        <!-- <div> 
          <b-form-group id="fieldset-1" label="Enter Title">
            <b-form-input
              id="input-1"
              v-model="explanations.title"
              trim
            ></b-form-input>
          </b-form-group>
        </div> -->

        <div>
          <b-form-group id="fieldset-1" label="Enter Type">
            <vue-editor v-model="explanations.type"></vue-editor>
          </b-form-group>
        </div>

        <div class="error-message" v-if="Object.keys(errorList).length">
          <b-alert show variant="danger">
            <template v-for="(error, index) in Object.keys(errorList)">
              <div :key="index">
                <strong>{{ error }}:</strong>
                <p>
                  {{ errorList[error] }}
                </p>
              </div>
            </template>
          </b-alert>
        </div>
        <b-button variant="primary" block @click="saveExplanations()"
          >Save</b-button
        >
      </div>
    </b-modal>
    <!-- Delete explanation modal -->
    <b-modal
      hide-footer
      ref="deleteExplanationModal"
      title="Delete Confirmation"
    >
      <p class="my-4">Are you Sure you want to Delete the topic explanation?</p>
      <b-button variant="primary" @click="deleteExplanation(explanations)"
        >Delete</b-button
      >&nbsp;&nbsp;
      <b-button @click="closeModal()">Cancel</b-button>
    </b-modal>
  </div>
</template>

<script>
import Banner from "../components/banner/banner.vue";
import TopicCard from "../components/topic/topic.vue";
import SubTopicCard from "../components/subtopic/subtopic.vue";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import axios from "axios";
import { VueEditor } from "vue2-editor";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

export default {
  name: "Home",
  components: {
    Banner,
    VueSlickCarousel,
    TopicCard,
    SubTopicCard,
    VueEditor,
  },
  data() {
    return {
      customToolbar: [
        // ["bold", "italic", "underline"],
        // [{ list: "ordered" }, { list: "bullet" }],
        // ["image", "code-block"]
      ],
      showSubTopicTree: false,

      activeIndex: undefined,
      isSubTopic: false,
      mainSliderLength: 3,
      updateHorizontalSlider: 12345,
      errorList: {},
      topic: {
        name: "",
        type: null,
        parent: null,
        private: false,
      },
      explanations: {
        title: "",
        type: "",
        topic: null,
      },
      explanation_images: {
        explanation: "",
        image: [],
      },
      typeList: [
        { label: "Diary", value: "diary" },
        { label: "Literary Novel", value: "literary_novel" },
        { label: "Course", value: "course" },
        { label: "Campaign", value: "campaign" },
        { label: "General Topic", value: "General_topic" },
        { label: "Faq Topic", value: "faq_topic" },
        { label: "Customer Hierarchy", value: "customer_hierarchy" },
        { label: "Reference", value: "reference" },
      ],
      topicList: [],
      subTopicContent: [],

      slug: "",
      filteredChoices: [],
      autocompleteModel: false,
      email: "",
    };
  },
  computed: {
    treeUpdater() {
      return this.$store.state.treeUpdater;
    },
    getSubTopicSliderLength() {
      // console.log(this.allTopicList[this.currentTopicIndex]?.subtopics?.length > 5
      //   ? this.allTopicList[this.currentTopicIndex]?.subtopics?.length + 1
      //   : 6)
      return this.allTopicList[this.currentTopicIndex]?.subtopics?.length > 5
        ? this.allTopicList[this.currentTopicIndex]?.subtopics?.length + 1
        : 6;
    },
    showLevelUp() {
      let currentTopic = this.allTopicList[this.currentTopicIndex];
      return currentTopic?.parent;
    },
    breadcrumbList() {
      let list = [];
      let currentTopic = this.allTopicList[this.currentTopicIndex];
      list[0] = currentTopic?.name;
      if (currentTopic?.subtopics?.length) {
        if (currentTopic.subtopics[this.activeIndex]) {
          list.push(currentTopic.subtopics[this.activeIndex]?.name);
        }
      }
      if (currentTopic?.explanations?.length) {
        if (currentTopic.subtopics[this.activeIndex]) {
          if (currentTopic.subtopics[this.activeIndex]?.explanations) {
            list.push(
              currentTopic.subtopics[this.activeIndex]?.explanations[0]?.title
            );
          }
        }
      }

      return list;
    },
    breadcrumbListForModal() {
      let list = [];
      let currentTopic = this.allTopicList[this.currentTopicIndex];
      list[0] = currentTopic?.name;
      if (currentTopic?.subtopics?.length) {
        if (currentTopic.subtopics[this.activeIndex]) {
          list.push(currentTopic.subtopics[this.activeIndex]?.name);
        }
      }
      return list;
    },
    getParentMain() {
      return this.allTopicList[this.currentTopicIndex];
    },
    getParent() {
      let parent = {};
      let currentTopic = this.allTopicList[this.currentTopicIndex];
      parent = currentTopic;
      if (currentTopic?.subtopics?.length) {
        if (currentTopic.subtopics[this.activeIndex]) {
          parent = currentTopic.subtopics[this.activeIndex];
        }
      }
      return parent;
    },
    isLoggedIn() {
      return this.$store.state.isUserLoggedIn;
    },
    allTopicList() {
      // console.log('this.$store.state.topicList')
      // console.log(this.$store.state.topicList)
      return this.$store.state.topicList;
    },
    currentTopicIndex() {
      return this.$store.state.activeIndex;
    },
    isMobile() {
      return window.screen.width < 769;
    },
    isTablet() {
      return window.screen.width < 1040;
    },
    columnSettings() {
      return {
        infinite: true,
        // centerMode: this.isTablet?false:true,
        edgeFriction: 0.35,
        vertical: !this.isMobile,
        slidesToShow: this.isMobile ? 1 : 4,
      };
    },
    settings() {
      return {
        infinite: true,
        slidesToShow: this.mainSliderLength,
        slidesToScroll: 1,
      };
    },
    getExplainations() {
      if (this.activeIndex !== undefined) {
        return (
          this.allTopicList[this.currentTopicIndex]?.subtopics[this.activeIndex]
            ?.explanations || []
        );
      } else {
        return this.allTopicList[this.currentTopicIndex].explanations;
      }
    },
    getContributors() {
      var array = []
      if (this.activeIndex !== undefined) {
        array = this.allTopicList[this.currentTopicIndex]?.subtopics[this.activeIndex]
            ?.updated_by || []
      }
      else{
        array = this.allTopicList[this.currentTopicIndex]?.updated_by || []
      }
        var flags = [],
          output = [],
          l = array.length,
          i;
          if(l!=0){
          for (i = 0; i < l; i++) {
            if (flags[array[i].history_user]) continue;
            flags[array[i].history_user] = true;
            output.push(array[i]);
          }
        }
        else{
          output = [{
            history_user_name:this.allTopicList[this.currentTopicIndex].user_name
          }]
        }
        // for (i = 0; i < l; i++) {
        //   if (flags[array[i].history_user]) continue;
        //   flags[array[i].history_user] = true;
        //   output.push(array[i]);
        // }
        return output
    // }
      // } else {
      //   var 
      //   var flags = [],
      //     output = [],
      //     l = array.length,
      //     i;
        
      //   return output
      // }
    },
    getSubTopics() {
      // console.log("this.$store.state.topicTree");
      // console.log(this.$store.state.topicTree);
      return this.$store.state.topicTree;
    },
    sliderSize() {
      // console.log(this.$store.state.sliderSize);
      return this.$store.state.sliderSize + 1;
    },
    allExplanationTitleChoices() {
      return this.$store.state.explanation_title_choices;
    },
  },
  watch: {
    currentTopicIndex() {
      this.activeIndex = undefined;
      // this.getTopicList();                          // commented on 21 dec to fix searching revert problem
      this.$store.commit("UPDATE_SLUG_ID", undefined);
    },
  },
  mounted() {
    this.slug = this.$route.params.slug;
    if (this.slug) {
      // console.log("this.slug");
      // console.log(this.slug);
      this.getSharedTopic();
    } else if (!this.allTopicList.length) {
      this.getTopicList();
    }
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
    // console.log('allExplanationTitleChoices',this.allExplanationTitleChoices)
    if (!this.allExplanationTitleChoices.length) {
      this.getExplainationTitleChoices();
    }
  },
  methods: {
    // For Autocomplete explanation title
    getExplainationTitleChoices() {
      const config = {
        headers: {
          authorization: `${this.$store.state.token}`,
        },
      };
      axios
        .get(
          `${process.env.VUE_APP_API_URL}/api/explanation_title_choices/`,
          config
        )
        .then((res) => {
          // console.log(res.data)
          this.$store.commit("UPDATE_EXPLANATION_TITLE_OPTIONS", res.data);
        });
    },
    setExplanationTitle(titlechoice) {
      // console.log('email',titlechoice)
      this.explanations.title = titlechoice.choice;
      this.autocompleteModel = false;
    },
    filterChoices() {
      // console.log('filterChoices working')
      if (this.explanations.title == "" || this.explanations.title == null) {
        this.filteredChoices = [];
      } else {
        this.filteredChoices = this.allExplanationTitleChoices.filter(
          (data, i) => {
            // console.log('filteredChoices data',data)
            return data.choice
              .toLowerCase()
              .startsWith(this.explanations.title.toLowerCase());
          }
        );
      }
      // return email.toLowerCase().startsWith(this.email.toLowerCase());
    },
    // For Export Topic HTML
    downloadPDF() {
      var treeEl = document.createElement("div");
      var h1 = document.createElement("h1");
      h1.style.fontWeight = "bold";
      h1.style.fontSize = "32px";
      var hTOC = document.createElement("p");
      var str = this.getSubTopics.name.replace(/\s/g, "&nbsp;");

      let idContent = this.getSubTopics.name.replace(/ /g, "_");
      h1.setAttribute("id", idContent);

      hTOC.setAttribute("class", "main_heading");
      //	Creating Elements
      let link = document.createElement("a");
      // Adding Content
      link.textContent = this.getSubTopics.name;
      link.href = `#${idContent}`;
      hTOC.appendChild(link);
      // console.log("hTOC");
      // console.log(hTOC);
      h1.innerHTML = str;
      treeEl.appendChild(h1);
      treeEl.appendChild(this.to_explanation(this.getSubTopics.explanations));

      // console.log(this.getSubTopics);
      var topics = this.to_ul(this.getSubTopics.subtopics, 1)[0];
      var toC = this.to_ul(this.getSubTopics.subtopics, 1)[1];
      treeEl.appendChild(topics);

      var newWindow = window.open();
      newWindow.document.write(
        "<html><head><title>" + document.title + "</title>"
      );

      newWindow.document.write(`<style>
@charset "UTF-8";
*, :after, :before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

body {
    margin: 0;
    font-family: sans-serif
}

.container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    width: 100%
}

.item {
    margin: 0;
    padding-left: 0.9375em;
    padding-right: 0.9375em
}

.item {
    width: 100%
}

.item.container {
    padding-left: 0;
    padding-right: 0
}

.item.s-100, .s-100 {
    width: 100%
}

@media (min-width:640px) {
    .item.m-30, .m-30 {
        width: 30%
    }
    .item.m-70, .m-70 {
        width: 70%
    }
}

html {
    font-family: sans-serif;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%
}

body {
    margin: 0
}

article, aside, main, nav, section {
    display: block
}

body {
    background: #EDEBE6;
    color: #444A58;
    font-family: Ubuntu;
    font-size: 15px;
    line-height: 1.5
}

.main .title {
    text-align: center;
    margin: 2rem
}

.article {
    background: #fff;
    padding: 1rem 2rem
}


.tc h1 {
    border-bottom: 1px solid #bbb;
    color: #555;
    font-size: 16px;
    margin: 0;
    margin-bottom: .5rem;
    padding-bottom: .5rem;
    padding-top: .5rem;
    -webkit-transition: .5s all;
    -o-transition: .5s all;
    transition: .5s all;
}

.main_heading{
  font-size: 20px;
  font-weight:bold;
}

.tc a {
    color: #555;
    text-decoration: none;
}

.tc a:hover {
    color: #333;
    text-decoration: underline;
}

.tc h2, .tc h3, .tc h4, .tc h5, .tc h6 {
    font-size: 13px;
    margin: 0;
}

.tc h2 {
    margin-left: 0.5rem;
}

.tc h2:before {
    content: ' - ';
}

.tc h3 {
    margin-left: 1rem;
}

.tc h3:before {
    content: ' - ';
}

.tc h4 {
    margin-left: 1.5rem;
}

.tc h4:before {
    content: ' - ';
}

.tc h5 {
    margin-left: 2rem;
}

.tc h5:before {
    content: ' - ';
}

.tc h6 {
    margin-left: 2.5rem;
}

.tc h6:before {
    content: ' - ';
}

.sidebar-block {
    background: #fff;
    margin-bottom: 1rem;
    padding: 1rem;
    position: fixed;
    width: 16rem
}
  ol {
  counter-reset: item;
  list-style-type: none;
}
ol>li { 
  counter-increment: item;
}
ol>li>:is(h1, h2, h3, h4, h5, h6)::before { 
  content: counters(item, '.')'- '; 
}  
#btnPrint{
  background-color: #F5F5F5;
  padding: 5px;
  width:50px;
  font-size: 15px;
  float: right;
   border-width: 1;
    border-radius: 25%;
    cursor:pointer;
}
@media print {
  #btnPrint, #toc, #upperbar {
    display: none;
  }
}
      </style>`);

      newWindow.document.write("</head><body >");
      newWindow.document.write(`
      
      <main class="main container">
      <section class="content container item">
        <h2 id="upperbar" class="title item"></h2>
        <article id="article" class="article item s-100 m-70">
        <button id='btnPrint' onclick="window.print()"> Print</button>
        ${treeEl.innerHTML}
        </article>
        <aside id="toc" class="sidebar item m-30">
          <div id="tc" class="sidebar-block">
          <nav class="tc">
            ${hTOC.outerHTML}
            ${toC.innerHTML}
            </nav>
          </div>
        </aside>
      </section>
    </main>`);
      newWindow.document.write("</body></html>");
    },
    forTableOfContent(subtopics, index) {},

    to_explanation(explanations) {
      var li = document.createElement("div");
      li.style.fontWeight = "normal";
      li.style.marginLeft = "5px";

      for (var i = 0, n = explanations.length; i < n; i++) {
        var explanation = explanations[i];

        var div = document.createElement("div");
        div.style.wordWrap = "break-word";
        div.innerHTML = explanation.type;
        li.appendChild(div);
      }
      return li;
    },
    to_ul(subtopics, index) {
      var ul = document.createElement("ol");
      let nav = document.createElement("nav");
      nav.classList.add("tc");
      for (var i = 0, n = subtopics.length; i < n; i++) {
        var subtopic = subtopics[i];
        var li = document.createElement("li");
        switch (index) {
          case 1:
            var h = document.createElement("h1");
            h.style.fontSize = "30px";
            break;
          case 2:
            var h = document.createElement("h2");
            h.style.fontSize = "28px";
            break;
          case 3:
            var h = document.createElement("h3");
            h.style.fontSize = "26px";
            break;
          case 4:
            var h = document.createElement("h4");
            h.style.fontSize = "24px";
            break;
          case 5:
            var h = document.createElement("h5");
            h.style.fontSize = "22px";
            break;
          default:
            var h = document.createElement("h6");
            h.style.fontSize = "20px";
        }

        li.style.fontWeight = "bold";

        h.innerHTML += subtopic.name;
        // adding id
        let idContent = subtopic.name.replace(/ /g, "_");
        li.setAttribute("id", idContent);

        //	Creating Elements
        let title = document.createElement(h.nodeName);
        let link = document.createElement("a");

        // Adding Content
        link.textContent = subtopic.name;
        link.href = `#${idContent}`;

        title.appendChild(link); //h>a
        nav.appendChild(title);

        li.appendChild(h);

        if (subtopic.explanations) {
          li.appendChild(this.to_explanation(subtopic.explanations));
        }
        if (subtopic.subtopics) {
          li.appendChild(this.to_ul(subtopic.subtopics, index + 1)[0]);
          nav.appendChild(this.to_ul(subtopic.subtopics, index + 1)[1]);
        }
        // nav.appendChild(title);
        ul.appendChild(li);
      }
      return [ul, nav];
    },

    // For Sharing Topic
    getSharedTopic() {
      const config = {
        headers: {
          authorization: `${this.$store.state.token}`,
        },
      };
      axios
        .get(`${process.env.VUE_APP_API_URL}/share_topic/${this.slug}/`, config)
        .then((res) => {
          // console.log(res.data);
          this.$store.commit("UPDATE_SLUG_ID", res.data.topic);
          this.getTopicList();
        });
    },
    // till here

    // For SubTopic Tree on Home Page

    async getTopicTreeData(id) {
      let node = await this.getData(id);
      this.$store.commit("UPDATE_TOPIC_TREE_LIST", node);
    },
    async getData(id) {
      try {
        const config = {
          headers: {
            authorization: `${this.$store.state.token}`,
          },
        };
        let response = await axios.get(
          `${process.env.VUE_APP_API_URL}/topics/${id}/`,
          config
        );
        response.data.subtopics.forEach(async (topic, index) => {
          response.data.subtopics[index] = await this.getData(topic.id);
        });
        this.$store.commit("UPDATE_TREE", 0);
        return response.data;
      } catch (err) {
        return [];
      }
    },

    //  For SubTopic Tree on Home Page

    // QuillEditor
    handleImageAdded: function (file, Editor, cursorLocation, resetUploader) {
      // An example of using FormData
      // NOTE: Your key could be different such as:
      // formData.append('file', file)
    },

    moveLevelUp() {
      // console.log('movelevelup working')
      let currentTopic = this.allTopicList[this.currentTopicIndex];
      // console.log(currentTopic)
      // console.log(this.$store.state.parent)
      if (this.$store.state.parent) {
        // For subtopic Tree on home page
        this.getTopicTreeData(this.$store.state.parent);
        // till here subtopic Tree on home page
        const config = {
          headers: {
            authorization: `${this.$store.state.token}`,
          },
        };
        axios
          .get(
            `${process.env.VUE_APP_API_URL}/topics/
            ${this.$store.state.parent}/`,
            // ${currentTopic.parent}/`,

            config
          )
          .then((res) => {
            let level1 = res.data;
            axios
              .get(
                `${process.env.VUE_APP_API_URL}/topics/${
                  level1.parent ? level1.parent + "/" : ""
                }`,
                config
              )
              .then((res) => {
                let list = res.data.subtopics ? res.data.subtopics : res.data;
                var index = undefined;
                list.forEach((t, i) => {
                  if (t.id === level1.id) {
                    index = i;
                  }
                });
                list[index] = level1;
                this.$store.commit("UPDATE_ACTIVE_INDEX", index);
                this.$store.commit("UPDATE_ACTIVITY_LIST", list);
                this.$store.commit("UPDATE_PARENT", level1.parent);

                setTimeout(() => {
                  this.goTo(0);
                }, 2000);
              });
          });
      }
    },
    openAddTopic(flag) {
      this.isSubTopic = flag;
      this.$refs["createTopicModal"].show();
    },
    onResize() {
      if (window.screen.width > 900 && window.screen.width < 1280) {
        this.mainSliderLength = 2;
      } else if (window.screen.width < 900) {
        this.mainSliderLength = 1;
      } else {
        this.mainSliderLength = 3;
      }
      this.updateHorizontalSlider = Math.random();
    },
    goTo(index) {
      if (index == 0) {
        this.$refs.mainSlider.goTo(this.currentTopicIndex);
      } else if (this.activeIndex !== undefined) {
        this.$refs.subtopicSlider.goTo(this.activeIndex);
      }
    },
    getTopicList(focused = false, id = 0) {
      // console.log('getTopicList working')
      const config = {
        headers: {
          authorization: `${this.$store.state.token}`,
        },
      };
      if (this.slug && this.$store.state.slugTopicId) {
        axios
          .get(
            `${process.env.VUE_APP_API_URL}/topics/${this.$store.state.slugTopicId}/`,
            config
          )
          .then((res) => {
            this.$store.commit("UPDATE_ACTIVITY_LIST", [res.data]);

            this.$store.commit("UPDATE_ACTIVE_INDEX", 0);
            this.$store.commit("UPDATE_PARENT", res.data.parent);
          });
      } else if (this.$store.state.parent) {
        axios
          .get(
            `${process.env.VUE_APP_API_URL}/topics/${this.$store.state.parent}/`,
            config
          )
          .then((res) => {
            res.data.subtopics.forEach((element, i) => {
              if (!element.subtopics) {
                element.subtopics = [];
              }
              if (!element.explanations) {
                element.explanations = [];
              }
              if (focused && !this.isSubTopic && element.id == id) {
                this.$store.commit("UPDATE_ACTIVE_INDEX", i);
              }
            });
            this.getTopicById(res.data.subtopics, focused, id);
          });
      } else {
        axios
          .get(`${process.env.VUE_APP_API_URL}/topics/`, config)
          .then((res) => {
            if (focused && !this.isSubTopic && res?.data?.length) {
              this.$store.commit("UPDATE_ACTIVE_INDEX", res.data.length - 1);
              setTimeout(() => {
                this.goTo(0);
              }, 1000);
            }
            this.$store.commit("UPDATE_ACTIVITY_LIST", res.data);
          });
      }
    },
    getTopicById(tree, focused, id) {
      const config = {
        headers: {
          authorization: `${this.$store.state.token}`,
        },
      };
      axios
        .get(
          `${process.env.VUE_APP_API_URL}/topics/${
            tree[this.currentTopicIndex].id
          }/`,
          config
        )
        .then((res) => {
          tree[this.currentTopicIndex] = res.data;
          res.data.subtopics.forEach((topic, index) => {
            if (focused && this.isSubTopic && topic.id == id) {
              this.activeIndex = index;
            }
          });
          this.$store.commit("UPDATE_ACTIVITY_LIST", tree);
        });
    },
    saveTopic() {
      const config = {
        headers: {
          authorization: `${this.$store.state.token}`,
        },
      };
      if (this.isSubTopic) {
        this.topic.parent = this.getParentMain?.id
          ? this.getParentMain?.id
          : null;
        this.topic.type = this.getParentMain?.type
          ? this.getParentMain?.type
          : null;
        this.topic.private = this.getParentMain?.private;
      } else {
        this.topic.parent = this.getParentMain?.parent
          ? this.getParentMain.parent
          : null;
        if (this.topic.parent != null) {
          this.topic.type = this.getParentMain?.type;
          this.topic.private = this.getParentMain?.private;
        }
      }
      // console.log(this.topic)
      axios
        .post(`${process.env.VUE_APP_API_URL}/topics/`, this.topic, config)
        .then((res) => {
          this.topic = {
            name: "",
            type: null,
            parent: null,
            private: false,
          };
          this.$refs["createTopicModal"].hide();
          this.getTopicList(true, res.data.id);
        });
    },
    saveExplanations() {
      // console.log(this.explanations);
      const config = {
        headers: {
          authorization: `${this.$store.state.token}`,
        },
      };
      this.explanations.topic = this.getParent.id;
      let method = this.explanations.id ? "put" : "post";
      let url = this.explanations.id
        ? `${process.env.VUE_APP_API_URL}/explanations/${this.explanations.id}/`
        : `${process.env.VUE_APP_API_URL}/explanations/`;
      axios[method](url, this.explanations, config)
        .then(() => {
          this.errorList = {};
          this.explanations = {
            id: null,
            title: "",
            type: "",
            topic: null,
          };
          this.$refs["createExplanationsModal"].hide();
          this.getTopicList();
        })
        .catch((err) => {
          this.errorList = err.response.data;
        });
    },
    editExplaination(item) {
      this.explanations = item;
      this.$refs["createExplanationsModal"].show();
    },

    // For delete modal of explanation
    closeModal() {
      this.$refs["deleteExplanationModal"].hide();
    },
    delExplanation(item) {
      this.explanations = item;
      // alert(JSON.stringify(item))
      this.$refs["deleteExplanationModal"].show();
    },
    deleteExplanation(explaination) {
      const config = {
        headers: {
          authorization: `${this.$store.state.token}`,
        },
      };
      axios
        .delete(
          `${process.env.VUE_APP_API_URL}/explanations/${explaination.id}/`,
          config
        )
        .then((res) => {
          // console.log(res);
          this.getTopicList();
          this.$refs["deleteExplanationModal"].hide();
        })
        .catch((e) => {
          // console.log(e);
          // let keys = Object.keys(e.response.data);
          // this.$bvToast.toast(`${e.response.data[keys[0]]}`, {
          //   title: "Warning",
          //   autoHideDelay: 10000,
          //   visible: true,
          //   variant: "warning",
          //   appendToast: true,
          // });
        });
    },
  },
};
</script>

<style lang="scss">
.emailselect {
  overflow-y: scroll;
  position: absolute;
  width: 93%;
  max-height: 200px;
  z-index: 999;
}
.email-bg {
  padding: 7px;
  background-color: #ecf1f5;
  border: #d1d7db 0.5px solid;
}
.home {
  .card.addTopic {
    &.parent-add {
      margin: auto;
    }
    height: 150px;
    width: 200px;
    text-align: center;
    &.addSubTopic {
      width: 250px;
    }
    .card-body {
      display: flex;
      justify-content: center;
      align-items: center;
      .card-title {
        font-weight: bold;
        color: $primary;
      }
    }
  }
  .sub-topic-content {
    .text {
      font-size: 18px;
      line-height: 32px;
    }
  }
  .files-card {
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-between;
    align-items: flex-end;
    .file-item {
      margin: 0px 2px;
      // margin-left: 20px;
      // &:first-child{
      //   margin-left: 0;
      // }
      .title {
        font-size: 5px;
      }
      .card {
        width: 200px;
        height: 50px;
        .card-body {
          display: flex;
          align-items: center;
          // justify-content: center;
          .card-title {
            margin: 0 !important;
            // color: #999;
            font-size: 20px;
          }
        }
      }
    }
  }
}
// .slick-vertical {
//   .topic {
//     margin-bottom: 15px;
//   }
// }
.breadcrumb {
  background-color: #fff !important;
  padding: 0 15px !important;
  .breadcrumb-item a {
    text-transform: capitalize;
  }
  .breadcrumb-item.active a {
    color: #777;
    text-decoration: none;
  }
  .breadcrumb-item + .breadcrumb-item::before {
    content: "" !important;
    background: url(../assets/img/divider.svg) no-repeat;
    background-size: cover;
    height: 28px;
    position: relative;
    right: 6px;
    top: -1px;
    width: 14px;
  }
}
.breadcrumb-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  &.grey-bg .breadcrumb {
    background-color: #ddd !important;
  }
}
.home .custom-arrow.right {
  right: -25px !important;
}
.home.mobile .custom-arrow.right {
  right: -18px !important;
}
.home.mobile .custom-arrow.left {
  left: -18px !important;
}
.custom-arrow.top {
  top: -45px;
  transform: rotate(270deg);
  left: 40%;
}
.custom-arrow.bottom {
  top: unset;
  bottom: -45px;
  transform: rotate(270deg);
  left: 40%;
}
.home.mobile .custom-arrow.top,
.home.mobile .custom-arrow.bottom {
  left: 42%;
}
.subtopicSlider-container,
.subtopicSlider-container .slick-list {
  min-height: 800px !important;
}

// SubTopic Tree

.node-tree {
  width: 100%;
  height: 700px;
  // border: 1px solid #ccc;
  .node {
    border-radius: 5px;
    &.collapsed-node {
      border: 1px solid $primary;
    }
  }
}
.slider {
  position: relative;
  @media all and (max-width: 1024px) {
    max-width: 100%;
    overflow-x: auto;
  }
}
</style>
